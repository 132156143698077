const RightArrow = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.5 17.75C10.2891 17.75 10.1016 17.6797 9.96094 17.5391C9.65625 17.2578 9.65625 16.7656 9.96094 16.4844L13.9219 12.5L9.96094 8.53906C9.65625 8.25781 9.65625 7.76562 9.96094 7.48438C10.2422 7.17969 10.7344 7.17969 11.0156 7.48438L15.5156 11.9844C15.8203 12.2656 15.8203 12.7578 15.5156 13.0391L11.0156 17.5391C10.875 17.6797 10.6875 17.75 10.5 17.75Z"
      fill={color ? color : "white"}
    />
  </svg>
);
export default RightArrow;
