const CustomTabs = ({
  active = 0,
  tabsList = [
    { id: 1, title: "Details" },
    { id: 2, title: "Members" },
    { id: 3, title: "Campaigns" },
  ],
  onClick,
  isLightTabColor,
}) => {
  return (
    <div className="flex gap-5  justify-between text-base leading-6 whitespace-nowrap text-neutral-300">
      {tabsList.map((item, index) => (
        <button
          key={item.id + index}
          onClick={() => onClick(index)}
          className={
            index === active ? "font-semibold text-blue-800" : "text-gray-300"
          }
        >
          {item.title}
          <div
            className={`shrink-0 mt-2.5 h-0.5 rounded-2xl mr-3 ${
              index === active ? "bg-blue-800" : ""
            }`}
          />
        </button>
      ))}
    </div>
  );
};
export default CustomTabs;
