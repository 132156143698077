import { toast } from "react-toastify";
import {
  LOGIN_URL,
  FORGOT_PWD_URL,
  LOGOUT_URL,
  GET_ROLE_RIGHTS_URL,
  CHANGE_PWD_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const login = (body) =>
  dataService
    .post(LOGIN_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const forgotPassword = (body) =>
  dataService
    .post(FORGOT_PWD_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const resetPassword = (body) =>
  dataService
    .post(FORGOT_PWD_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const changePassword = (body) =>
  dataService
    .post(CHANGE_PWD_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const getRoleRights = (body) =>
  dataService
    .post(GET_ROLE_RIGHTS_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const logout = (body) =>
  dataService
    .post(LOGOUT_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
export {
  login,
  changePassword,
  logout,
  resetPassword,
  getRoleRights,
  forgotPassword,
};
