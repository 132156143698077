import QuestionIcon from "../../../../assets/icons/QuestionIcon";
import CustomLightButton from "../../../../components/CustomLightButton/CustomLightButton";

const FAQ = () => {
  return (
    <div className="flex flex-col">
      <div className="px-5 self-start text-[16px] text-blue leading-[20px] font-bold max-md:max-w-full">
        FAQ
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex gap-2.5 px-10 mt-10 max-md:flex-wrap max-md:px-5">
        <span className="shrink-0 self-start aspect-[0.33] w-[22px]">
          <QuestionIcon />
        </span>

        <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
          <div className="font-bold leading-[143%] text-blue max-md:max-w-full">
            What is the meaning of Lorem ipsum?
          </div>
          <div className="mt-1 leading-6 text-zinc-600 max-md:max-w-full">
            Literally it does not mean anything. It is a sequence of words
            without a sense of Latin derivation that make up a text also known
            as filler text, fictitious, blind or placeholder
          </div>
        </div>
      </div>
      <div className="flex gap-2.5 px-10 mt-8 max-md:flex-wrap max-md:px-5">
        <span className="shrink-0 self-start aspect-[0.33] w-[22px]">
          <QuestionIcon />
        </span>
        <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
          <div className="font-bold leading-[143%] text-blue max-md:max-w-full">
            Why is Lorem Ipsum Dolor used?
          </div>
          <div className="mt-1 leading-6 text-zinc-600 max-md:max-w-full">
            The Lorem Ipsum text is used to fill spaces designated to host texts
            that have not yet been published. They use programmers, graphic
            designers, typographers to get a real impression of the digital /
            advertising / editorial product they are working on.
          </div>
        </div>
      </div>
      <div className="flex gap-2.5 px-10 mt-8 max-md:flex-wrap max-md:px-5">
        <span className="shrink-0 self-start aspect-[0.33] w-[22px]">
          <QuestionIcon />
        </span>

        <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
          <div className="font-bold leading-[143%] text-blue max-md:max-w-full">
            What are the origins of Lorem Ipsum Dolor Sit?
          </div>
          <div className="mt-1 leading-6 text-zinc-600 max-md:max-w-full">
            Its origins date back to 45 BC. In fact, his words were randomly
            extracted from the De finibus bonorum et malorum , a classic of
            Latin literature written by Cicero over 2000 years ago.
          </div>
        </div>
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex gap-5 px-10 mt-5 max-md:flex-wrap max-md:px-5">
        <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
          <div className="font-bold leading-[143%] text-blue max-md:max-w-full">
            Do you still have questions?
          </div>
          <div className="mt-1 leading-6 text-zinc-600 max-md:max-w-full">
            Please visit our Help Center for assistance. Alternatively, you can
            contact our Support team via Customer Chat (look for the blue chat
            icon in the bottom-right corner) or by emailing
            support@ban_media.com.
          </div>
        </div>
        <CustomLightButton title="Book a Meeting" />
      </div>
    </div>
  );
};
export default FAQ;
