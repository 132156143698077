import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "../../apis/Auth";
import Login from "../../assets/images/login.png";
import Logo from "../../assets/images/logo-dark.png";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import queryParamConstant from "../../utils/queryParamConstant";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const email_token = "abc";

  const { mutate: submitLogin } = useMutation(
    queryParamConstant.RESET_PWD_URL,
    () =>
      resetPassword({
        email_token: email_token,
        password: password,
      }),
    {
      enabled: !!password,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        navigate("/login");
      },
    }
  );

  const handleSubmit = () => {
    if (email_token !== "" && password !== "") {
      submitLogin();
    } else {
      toast.error("All field is required!", {
        toastId: "1",
      });
    }
  };
  return (
    <div className="flex justify-center items-center px-10 py-10 bg-slate-50 max-md:px-5">
      <div className="flex flex-col justify-center mt-10 max-w-full bg-white rounded-xl shadow-2x w-[900px]">
        <div className="pr-14 bg-white rounded-xl border-2 border-solid border-gray-300 max-md:pr-5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src={Login}
                alt="Login"
                className="grow w-full aspect-[0.72] max-md:mt-10 rounded-s-lg"
              />
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-sm font-bold leading-5 text-slate-800 max-md:mt-10 max-md:max-w-full">
                <div className="self-center text-[24px] leading-[28px] font-bold">
                  Log In
                </div>
                <img
                  loading="lazy"
                  src={Logo}
                  alt="Logo"
                  className="self-center my-6 max-w-full aspect-[4.35] w-[132px]"
                />
                <div className="my-2"></div>
                <CustomInput
                  type="password"
                  title="New Password"
                  placeholder="Enter"
                  value={password}
                  isPaddingZero
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="my-5"></div>
                <CustomInput
                  type="password"
                  title="Conform New Password"
                  placeholder="Enter"
                  value={confirmPassword}
                  isPaddingZero
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="self-end mt-3 mb-5 leading-[20px] text-[14px] font-medium text-red-500">
                  Forgot Password?
                </div>
                <CustomButton
                  title="Log In"
                  className="w-full"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordPage;
