import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import { formatDate } from "../../utils/formatDate";

const columns = [
  {
    name: "Billing Cycle",
    selector: (row) => row.start_date,
    sortable: true,
    width: "50%",
    cell: (row) => (
      <CustomTitle
        title={
          <>
            {formatDate(row.start_date)} - {formatDate(row.end_date)}
          </>
        }
      />
    ),
  },
  {
    name: "Message",
    selector: (row) => row.total_messages,
    sortable: true,
    width: "25%",
    cell: (row) => <CustomTitle title={row?.total_messages} />,
  },
  {
    name: "Amount",
    selector: (row) => row.total_amount,
    sortable: true,
    width: "25%",
    cell: (row) => <CustomTitle title={`$ ${row?.total_amount}`} />,
  },
];
export default columns;
