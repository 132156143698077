import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../components/RenderTableComponents/NormalText";
import Status from "../../components/RenderTableComponents/Status";
import formatNumber from "../../utils/formatNumber";

const columns = [
  {
    name: "subscribers",
    selector: (row) => row.flow_name,
    sortable: true,
    width: "30%",
    cell: (row) => <CustomTitle title={row?.flow_name} />,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    width: "13%",
    cell: (row) => row.status && <Status row={row} />,
  },
  {
    name: "Messages Sent",
    selector: (row) => row.messages_sent,
    sortable: true,
    width: "15%",
    cell: (row) => <NormalText row={formatNumber(row.messages_sent)} />,
  },
  {
    name: "Spent",
    selector: (row) => row.spent,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.spent)}`} />,
  },
  {
    name: "Sales",
    selector: (row) => row.sales,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.sales)}`} />,
  },
  {
    name: "RPM",
    selector: (row) => row.rpm,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.rpm)}`} />,
  },
  {
    name: "ROI",
    selector: (row) => row.roi,
    sortable: true,
    cell: (row) => <NormalText row={`${formatNumber(row.roi)}X`} />,
  },
];
export default columns;
