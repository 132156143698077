import { useState } from "react";
import CustomDropdowns from "../../CustomDropdowns/CustomDropdowns";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

const RenderFilter = ({
  isSearch = true,
  isLeftSideFilter = true,
  isDateFilter = true,
  customButtonRight,
  customButtonLeft,
  filterSelection,
  onFilterChange,
  onSearchFilter,
  searchFilter,
  leftSideFilter,
  startDateRange,
  endDateRange,
  setStartDateRange,
  setEndDateRange,
}) => {
  const [dateRange, setDateRange] = useState();
  const [dateRangeValue, setDateRangeValue] = useState([
    startDateRange,
    endDateRange,
  ]);
  const handleSelectRange = (data) => {
    if (data === "custom") {
      setDateRange(data);
    } else {
      onFilterChange(data);
    }
  };
  return (
    <div className="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
      <div className="flex gap-2 px-5 my-auto text-lg leading-7 text-neutral-700">
        {isLeftSideFilter && leftSideFilter}
        {customButtonLeft && customButtonLeft}
      </div>

      <div className="flex gap-5 px-5 max-md:flex-wrap">
        {isSearch && (
          <div className="flex flex-col flex-1 justify-center py-px text-sm whitespace-nowrap bg-white text-neutral-500">
            <div className="relative flex items-center ">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c725e12ba0bda12d49b671469b893e3766bf88f5d15c7fadda99f8e08f3d5929?"
                className="shrink-0 aspect-[1.06] fill-neutral-500 w-[17px] absolute ml-3"
                alt=""
              />
              <input
                value={searchFilter}
                onChange={(e) => onSearchFilter(e.target.value)}
                className="flex gap-2.5 outline-none h-[47px] pl-10 pr-3.5 py-2.5 rounded border border-gray-light border-solid w-[260px]"
                placeholder="Search..."
              />
            </div>
          </div>
        )}

        {isDateFilter &&
          (!dateRange ? (
            <CustomDropdowns
              onChange={(item) => handleSelectRange(item)}
              selected={filterSelection}
            />
          ) : (
            <Flatpickr
              className="flex h-[47px] px-5 py-2.5 rounded border border-gray-light border-solid"
              value={dateRangeValue}
              placeholder="Select Custom Range"
              options={{ mode: "range" }}
              onChange={(date) => {
                setDateRangeValue(date);
                setStartDateRange(date[0]);
                setEndDateRange();
                if (date.length > 1) {
                  setDateRange();
                  onFilterChange(dateRange);
                  setEndDateRange(date[1]);
                }
              }}
            />
          ))}

        {customButtonRight && customButtonRight}
      </div>
    </div>
  );
};
export default RenderFilter;

// <CustomButton
//   className="px-4"
//   title={
//     <>
//       <div className="text-[14px] leading-[22px] font-mediums mr-3">
//         All Time
//       </div>
//       <Line />
//     </>
//   }
// />
