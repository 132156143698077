const CustomButton = ({
  title = "Export",
  isEnd = true,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`flex justify-center items-center font-medium text-white whitespace-nowrap bg-blue-800 rounded leading-[157%] max-md:mr-2.5 h-[48px] ${
        className && className
      } ${isEnd ? "self-end" : ""} ${disabled ? "opacity-20" : ""}`}
      disabled={disabled}
      {...props}
    >
      {title}
    </button>
  );
};
export default CustomButton;
