/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "react-query";
import Items from "./Items";
import queryParamConstant from "../../utils/queryParamConstant";
import { getAllSubscribersChat } from "../../apis/Inbox";
import CustomLoader from "../CustomLoader";
import InfiniteScroll from "react-infinite-scroller";
import { useEffect, useState } from "react";
import Loader from "react-js-loader";

const MessageItemView = ({ subscriberId, onClickItem }) => {
  const [page, setPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [isScroll, setScroll] = useState(false);
  const { error, isLoading, refetch } = useQuery(
    queryParamConstant.GET_ALL_CHATS_URL,
    () =>
      getAllSubscribersChat({
        is_with_paginate: 1,
        page: page,
        limit: 10,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setScroll(false);
        setDataList([...dataList, ...data?.list]);
        if (page === 1) {
          onClickItem(data?.list?.[0]);
        }
      },
    }
  );
  useEffect(() => {
    if (page) {
      setTimeout(() => refetch(), 10);
    }
  }, [page]);
  const handleRefetch = () => {
    if (isScroll) {
      return;
    }
    const count = page + 1;
    setPage(count);
    setScroll(true);
  };

  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="flex flex-col w-[33%] bg-white rounded-t-xl border border-gray-light border-solid max-md:mt-10  relative border-b-0">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <div className="overflow-auto h-[100vh] message-view ">
          <InfiniteScroll
            pageStart={0}
            threshold={100}
            loadMore={() => handleRefetch()}
            hasMore={true || false}
            loader={
              <div
                className="loader flex justify-center items-center mt-3"
                key={0}
              >
                <Loader type="bubble-top" bgColor={"#2B61AB"} size={30} />
              </div>
            }
            useWindow={false}
          >
            {dataList?.map((item, index) => (
              <Items
                subscriberId={subscriberId}
                key={index}
                item={item}
                onClickItem={() => onClickItem(item)}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
export default MessageItemView;
