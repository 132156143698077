import { formatTime } from "../../utils/formatDate";
import { convertUTCDateToLocalDate } from "../../utils/parseTimeZone";

const Items = ({ item }) => (
  <div className="relative">
    {item?.message_direction === "outbound" ? (
      <div className="right-arrow"></div>
    ) : (
      <div className="left-arrow"></div>
    )}
    <div className="flex overflow-hidden flex-col py-4 my-3 text-sm leading-5 bg-white fill-white max-md:max-w-full rounded-lg">
      <div
        className="mx-6 max-md:mx-2.5 text-msg-text text-[14px] leading-[18px] break-words"
        dangerouslySetInnerHTML={{ __html: item?.message }}
      ></div>
      <div
        className={`flex max-md:max-w-full px-5 pt-2 text-msg-text text-[12px] leading-[18px] ${
          item?.message_direction === "outbound"
            ? "self-end"
            : "self-start flex-row-reverse"
        }`}
      >
        {item?.message_state === "failed" && (
          <div className="flex gap-2 capitalize max-md:max-w-full self-end text-[red] pr-3 text-[12px] leading-[18px] ">
            {item?.message_state}
          </div>
        )}
        {formatTime(convertUTCDateToLocalDate(item?.message_time))}
      </div>
    </div>
  </div>
);

export default Items;
