import CustomTitle from "../../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../../components/RenderTableComponents/NormalText";
import { formatDate } from "../../../utils/formatDate";

const columns = [
  {
    name: "Name",
    selector: (row) => row.phone_no,
    sortable: true,
    width: "70%",
    cell: (row) => (
      <CustomTitle
        title={row?.phone_no}
        description={""}
        id={row?.subscriber_id}
        link={"/subscribers/" + row.subscriber_id}
      />
    ),
  },
  {
    name: "Subscribed At",
    selector: (row) => row.members,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row.created_at)} />,
  },
  {
    name: "Last Updated",
    selector: (row) => row.members,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row.updated_at)} />,
  },
];
export default columns;
