const SegmentsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    className="w-4 stroke-gray-500 shrink-0"
  >
    <g>
      <path
        d="M14.86 0a1.14 1.14 0 0 0-1.14 1.14v13.72a3.43 3.43 0 0 0 3.43 3.43h13.71A1.14 1.14 0 0 0 32 17.14 17.16 17.16 0 0 0 14.86 0zm2.29 16A1.14 1.14 0 0 1 16 14.86V2.33A14.88 14.88 0 0 1 29.67 16z"
        fill="none"
        opacity="1"
        data-original="#000000"
        className="w-3 stroke-gray-500 shrink-0"
      ></path>
      <path
        d="M28.16 20.64a1.14 1.14 0 0 0-1.46.7 12.47 12.47 0 0 1-12.55 8.36 12.66 12.66 0 0 1-11.86-12 12.5 12.5 0 0 1 8.37-12.4 1.14 1.14 0 0 0-.76-2.16A14.77 14.77 0 0 0 0 17.76 15 15 0 0 0 14 32h.84a14.73 14.73 0 0 0 14-9.9 1.14 1.14 0 0 0-.68-1.46z"
        fill="none"
        opacity="1"
        data-original="#000000"
        className="w-3 stroke-gray-500 shrink-0"
      ></path>
    </g>
  </svg>
);
export default SegmentsIcon;
