import { useQuery } from "react-query";
import CustomLoader from "../CustomLoader";
import queryParamConstant from "../../utils/queryParamConstant";
import { getSingleCampaignFlows } from "../../apis/Home";
import RenderTable from "../RenderTable";
import columns from "./DatatableConfig";
import { Link } from "react-router-dom";
import { useState } from "react";

const RecentCampaignFlows = () => {
  const [selectedRange, setSelectedRange] = useState("all");
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_CAMPAIGN_FLOWS_LIST_URL_HOME,
    () =>
      getSingleCampaignFlows({
        filter: {
          search_text: "",
          popup_type: "",
          status: "",
          device: "",
          data_range: selectedRange,
          start_date: "",
          end_date: "",
        },
        page: 1,
        limit: 3,
        is_with_paginate: 1,
      }),
    { refetchOnWindowFocus: false }
  );
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="flex flex-col w-full ">
      <div className="justify-center px-5 py-[1.9rem] text-base font-bold leading-7 text-white bg-blue-800 rounded-xl border border-solid border-gray-light">
        Recent campaign flows
      </div>
      <div className="flex flex-col relative -mt-2 w-full text-sm bg-white rounded-none rounded-b-xl border-r border-b border-l border-solid border-gray-light">
        {isLoading || isFetching ? (
          <div className="h-[280px]">
            <CustomLoader />
          </div>
        ) : (
          <RenderTable
            isFilterLayout={false}
            columns={columns}
            tableData={data?.list}
            filterSelection={selectedRange}
            onFilterChange={(item) => {
              setSelectedRange(item);
              setTimeout(() => refetch(), 10);
            }}
          />
        )}
        <Link
          className="flex flex-col justify-end items-end px-5 pt-px pb-4 w-full font-medium text-blue-800 leading-[157%]"
          to="/campaign-flows"
        >
          <div className="justify-center px-5 py-1.5 bg-white rounded border border-solid border-gray-light">
            See All
          </div>
        </Link>
      </div>
    </div>
  );
};

export default RecentCampaignFlows;
