const ReceiptIcon = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 2h10.09a3 3 0 013 3v5a1 1 0 01-1 1H21V2z"
      fill="#E0F2FF"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.863 2l.034.21a3.002 3.002 0 00-1.892 2.614L29 5v33.314l-3-3-3 3-3-3-3 3-3-3-3 3-3-3-3 3V5a3 3 0 013-3h22.863z"
      fill="#fff"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M17 17a5 5 0 100-10 5 5 0 000 10zM23.5 21h-13a1.5 1.5 0 000 3h13a1.5 1.5 0 100-3zM23.5 28h-13a1.5 1.5 0 000 3h13a1.5 1.5 0 100-3z"
      fill="#E0F2FF"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
  </svg>
);
export default ReceiptIcon;
