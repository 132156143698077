import { toast } from "react-toastify";
import {
  GET_SUBSCRIBERS_LIST_URL,
  GET_SUBSCRIBERS_CHART_DATA_URL,
  GET_IMPORTED_FILES_LIST_URL,
  GET_SUBSCRIBERS_OVERVIEW_URL,
  GET_SUBSCRIBER_INFO_URL,
  GET_SUBSCRIPTION_HISTORY_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const listAllSubscribers = (body) =>
  dataService
    .post(GET_SUBSCRIBERS_LIST_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getImportSubscribers = (body) =>
  dataService
    .post(GET_IMPORTED_FILES_LIST_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getSubscribersChartDetails = (body) =>
  dataService
    .post(GET_SUBSCRIBERS_CHART_DATA_URL, body)
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const overallSubscribersAdded = (body) =>
  dataService
    .post(GET_SUBSCRIBERS_OVERVIEW_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const subscribersInfo = (body) =>
  dataService
    .post(GET_SUBSCRIBER_INFO_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const getSubscribersHistory = (body) =>
  dataService
    .post(GET_SUBSCRIPTION_HISTORY_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
export {
  getSubscribersHistory,
  subscribersInfo,
  overallSubscribersAdded,
  getSubscribersChartDetails,
  getImportSubscribers,
  listAllSubscribers,
};
