const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M8.88544 3.05214L10.9478 5.11453M9.76044 2.17714C10.33 1.60762 11.2533 1.60762 11.8228 2.17714C12.3923 2.74665 12.3923 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76044 2.17714Z"
      stroke="#111827"
      strokeWidth="1.2895"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditIcon;
