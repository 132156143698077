const DownIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8474 13.623H3.98356C3.62771 13.623 3.42902 13.2473 3.64939 12.9908L9.58132 6.1124C9.75111 5.91551 10.0781 5.91551 10.2497 6.1124L16.1816 12.9908C16.402 13.2473 16.2033 13.623 15.8474 13.623Z"
      fill={color ? color : "#2B61AB"}
    />
  </svg>
);
export default DownIcon;
