const OptInToolsRates = ({ row }) => (
  <>
    {row.email_optin_rate_per && row.email_optin_rate_per !== "-" ? (
      <p className="text-[14px] leading-[02px] font-medium">
        <span className="text-gray"> Email:</span>
        {row.email_optin_rate_per}%
      </p>
    ) : row.sms_optin_rate_per && row.sms_optin_rate_per !== "-0" ? (
      <p className="text-[14px] leading-[02px] font-medium">
        <span className="text-gray"> SMS:</span> {row.sms_optin_rate_per}%
      </p>
    ) : (
      <p>-</p>
    )}
  </>
);

export default OptInToolsRates;
