import { useEffect, useRef, useState } from "react";
import DownArrowIcon from "../../assets/icons/DownArrowIcon";
import CustomButton from "../CustomButton";
import CustomLightButton from "../CustomLightButton/CustomLightButton";

const CustomSortFilter = ({
  totalData,
  selected = "all",
  options,
  onChange,
  setSortPopupFilterData,
  setSortDeviceFilterData,
  setSortStatusFilterData,
  sortStatusFilterData,
  sortDeviceFilterData,
  sortPopupFilterData,
  onClick,
  onClearClick,
  isBorder = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);
  return (
    <div className="relative inline-block text-left" ref={btnRef}>
      <button
        type="button"
        className={`flex items-center whitespace-nowrap rounded leading-[157%] px-4 max-md:mr-2.5 self-end h-[48px] font-normal capitalize ${
          isBorder
            ? "text-[#5C5C5C] text-[14px] border-border-gray border-solid border-2 mr-3 h-[47px] min-w-[112px] justify-between"
            : "text-[#343A40] text-[18px]"
        }
          `}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={() => setOpen(!isOpen)}
      >
        <span>
          {selected} {totalData ? `(${totalData})` : ""}
        </span>
        <span className="ml-3">
          <DownArrowIcon />
        </span>
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-2 rounded-md shadow-lg origin-top-left left-0">
          <div className="rounded-md ring-1 ring-black ring-opacity-5 py-1 bg-white max-h-[400px] overflow-auto">
            {options ? (
              <div
                className="text-[14px] flex flex-col text-left justify-start items-start px-5 pt-5"
                role="none"
              >
                {Object.keys(options).map((item) => (
                  <button
                    key={item}
                    className={`mb-5 text-left capitalize ${
                      selected === item
                        ? "font-semibol text-blue-800"
                        : "font-normal text-blue"
                    } `}
                    onClick={() => {
                      onChange(item);
                      setOpen(false);
                    }}
                  >
                    {item}
                  </button>
                ))}
              </div>
            ) : (
              <div className="min-w-[150px]">
                <div className="p-4 border-b flex gap-24 pr-24">
                  <ul className="space-y-2">
                    <h3 className="text-gray font-medium text-[14px] mb-3">
                      Type
                    </h3>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortPopupFilterData.includes("popup")
                          ? setSortPopupFilterData([
                              ...sortPopupFilterData,
                              "popup",
                            ])
                          : setSortPopupFilterData([
                              ...sortPopupFilterData.filter(
                                (item) => item !== "popup"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="popup"
                        checked={sortPopupFilterData.includes("popup")}
                        onClick={(e) =>
                          !sortPopupFilterData.includes("popup")
                            ? setSortPopupFilterData([
                                ...sortPopupFilterData,
                                "popup",
                              ])
                            : setSortPopupFilterData([
                                ...sortPopupFilterData.filter(
                                  (item) => item !== "popup"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="popup"
                      >
                        Popup
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortPopupFilterData.includes("keyword")
                          ? setSortPopupFilterData([
                              ...sortPopupFilterData,
                              "keyword",
                            ])
                          : setSortPopupFilterData([
                              ...sortPopupFilterData.filter(
                                (item) => item !== "keyword"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="keyword"
                        checked={sortPopupFilterData.includes("keyword")}
                        onClick={(e) =>
                          !sortPopupFilterData.includes("keyword")
                            ? setSortPopupFilterData([
                                ...sortPopupFilterData,
                                "keyword",
                              ])
                            : setSortPopupFilterData([
                                ...sortPopupFilterData.filter(
                                  (item) => item !== "keyword"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="keyword"
                      >
                        Keyword
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortPopupFilterData.includes("landing_page")
                          ? setSortPopupFilterData([
                              ...sortPopupFilterData,
                              "landing_page",
                            ])
                          : setSortPopupFilterData([
                              ...sortPopupFilterData.filter(
                                (item) => item !== "landing_page"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="landing_page"
                        checked={sortPopupFilterData.includes("landing_page")}
                        onClick={(e) =>
                          !sortPopupFilterData.includes("landing_page")
                            ? setSortPopupFilterData([
                                ...sortPopupFilterData,
                                "landing_page",
                              ])
                            : setSortPopupFilterData([
                                ...sortPopupFilterData.filter(
                                  (item) => item !== "landing_page"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="landing_page"
                      >
                        Landing page
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortPopupFilterData.includes("customer_chat")
                          ? setSortPopupFilterData([
                              ...sortPopupFilterData,
                              "customer_chat",
                            ])
                          : setSortPopupFilterData([
                              ...sortPopupFilterData.filter(
                                (item) => item !== "customer_chat"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="customer_chat"
                        checked={sortPopupFilterData.includes("customer_chat")}
                        onClick={(e) =>
                          !sortPopupFilterData.includes("customer_chat")
                            ? setSortPopupFilterData([
                                ...sortPopupFilterData,
                                "customer_chat",
                              ])
                            : setSortPopupFilterData([
                                ...sortPopupFilterData.filter(
                                  (item) => item !== "customer_chat"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="customer_chat"
                      >
                        Customer chat
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortPopupFilterData.includes("widget")
                          ? setSortPopupFilterData([
                              ...sortPopupFilterData,
                              "widget",
                            ])
                          : setSortPopupFilterData([
                              ...sortPopupFilterData.filter(
                                (item) => item !== "widget"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="widget"
                        checked={sortPopupFilterData.includes("widget")}
                        onClick={(e) =>
                          !sortPopupFilterData.includes("widget")
                            ? setSortPopupFilterData([
                                ...sortPopupFilterData,
                                "widget",
                              ])
                            : setSortPopupFilterData([
                                ...sortPopupFilterData.filter(
                                  (item) => item !== "widget"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="widget"
                      >
                        Widget
                      </label>
                    </li>
                  </ul>
                  <ul className="space-y-2">
                    <h3 className="text-gray font-medium text-[14px] mb-3">
                      Status
                    </h3>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortStatusFilterData.includes("active")
                          ? setSortStatusFilterData([
                              ...sortStatusFilterData,
                              "active",
                            ])
                          : setSortStatusFilterData([
                              ...sortStatusFilterData.filter(
                                (item) => item !== "active"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="active"
                        checked={sortStatusFilterData.includes("active")}
                        onClick={(e) =>
                          !sortStatusFilterData.includes("active")
                            ? setSortStatusFilterData([
                                ...sortStatusFilterData,
                                "active",
                              ])
                            : setSortStatusFilterData([
                                ...sortStatusFilterData.filter(
                                  (item) => item !== "active"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="active"
                      >
                        Active
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortStatusFilterData.includes("draft")
                          ? setSortStatusFilterData([
                              ...sortStatusFilterData,
                              "draft",
                            ])
                          : setSortStatusFilterData([
                              ...sortStatusFilterData.filter(
                                (item) => item !== "draft"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="draft"
                        checked={sortStatusFilterData.includes("draft")}
                        onClick={(e) =>
                          !sortStatusFilterData.includes("draft")
                            ? setSortStatusFilterData([
                                ...sortStatusFilterData,
                                "draft",
                              ])
                            : setSortStatusFilterData([
                                ...sortStatusFilterData.filter(
                                  (item) => item !== "draft"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="draft"
                      >
                        Draft
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortStatusFilterData.includes("inactive")
                          ? setSortStatusFilterData([
                              ...sortStatusFilterData,
                              "inactive",
                            ])
                          : setSortStatusFilterData([
                              ...sortStatusFilterData.filter(
                                (item) => item !== "inactive"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="inactive"
                        checked={sortStatusFilterData.includes("inactive")}
                        onClick={(e) =>
                          !sortStatusFilterData.includes("inactive")
                            ? setSortStatusFilterData([
                                ...sortStatusFilterData,
                                "inactive",
                              ])
                            : setSortStatusFilterData([
                                ...sortStatusFilterData.filter(
                                  (item) => item !== "inactive"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="inactive"
                      >
                        InActive
                      </label>
                    </li>
                  </ul>
                  <ul className="space-y-2">
                    <h3 className="text-gray font-medium text-[14px] mb-3">
                      Device
                    </h3>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortDeviceFilterData.includes("mobile")
                          ? setSortDeviceFilterData([
                              ...sortDeviceFilterData,
                              "mobile",
                            ])
                          : setSortDeviceFilterData([
                              ...sortDeviceFilterData.filter(
                                (item) => item !== "mobile"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="mobile"
                        checked={sortDeviceFilterData.includes("mobile")}
                        onClick={(e) =>
                          !sortDeviceFilterData.includes("mobile")
                            ? setSortDeviceFilterData([
                                ...sortDeviceFilterData,
                                "mobile",
                              ])
                            : setSortDeviceFilterData([
                                ...sortDeviceFilterData.filter(
                                  (item) => item !== "mobile"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="mobile"
                      >
                        Mobile
                      </label>
                    </li>
                    <li
                      className="flex items-center gap-2"
                      onClick={(e) =>
                        !sortDeviceFilterData.includes("desktop")
                          ? setSortDeviceFilterData([
                              ...sortDeviceFilterData,
                              "desktop",
                            ])
                          : setSortDeviceFilterData([
                              ...sortDeviceFilterData.filter(
                                (item) => item !== "desktop"
                              ),
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id="desktop"
                        checked={sortDeviceFilterData.includes("desktop")}
                        onClick={(e) =>
                          !sortDeviceFilterData.includes("desktop")
                            ? setSortDeviceFilterData([
                                ...sortDeviceFilterData,
                                "desktop",
                              ])
                            : setSortDeviceFilterData([
                                ...sortDeviceFilterData.filter(
                                  (item) => item !== "desktop"
                                ),
                              ])
                        }
                      />
                      <label
                        className="text-blue text-[14px] font-normal shrink-0"
                        for="desktop"
                      >
                        Desktop
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="py-3 px-4 flex gap-3">
                  <CustomButton
                    title="Apply"
                    className="px-10"
                    onClick={() => {
                      onClick();
                      setOpen(false);
                    }}
                  />
                  <CustomLightButton
                    title="Clear all"
                    className="px-10"
                    onClick={() => {
                      onClearClick();
                      setOpen(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomSortFilter;
