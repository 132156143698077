const CustomInput = ({
  title,
  subTitle = "",
  isPaddingZero = false,
  className,
  value,
  onChange,
  ...props
}) => {
  return (
    <div
      className={`flex flex-col flex-1 max-md:px-5 w-full max-md:max-w-full ${
        isPaddingZero ? "px-0" : "px-10"
      } ${className && className}`}
    >
      {title && (
        <div className="font-bold text-blue max-md:max-w-full">{title}</div>
      )}
      {subTitle !== "" && (
        <div className="mt-1 leading-[157%] text-zinc-600 max-md:max-w-full">
          {subTitle}
        </div>
      )}
      <input
        value={value}
        onChange={onChange}
        className="justify-center px-3.5 py-2.5 mt-1 whitespace-nowrap rounded border border-gray-light border-solid text-neutral-500 max-md:max-w-full outline-none"
        {...props}
      />
    </div>
  );
};
export default CustomInput;
