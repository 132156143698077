import { Link } from "react-router-dom";

const YourAudience = () => {
  return (
    <div className="flex justify-center items-center px-16 py-16 bg-blue-800 rounded-xl border-2 border-blue-500 border-solid max-md:px-5">
      <div className="w-full max-w-[1069px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full">
            <img
              alt="data"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ead5afdb3684e236a3b4e12aa474fdaec3156d9349ed01050948d12f0d6bcca?"
              className="grow w-full aspect-[1.37] max-md:mt-10"
            />
          </div>
          <div className="flex flex-col ml-5 w-[63%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
              <div className="text-2xl font-semibold text-white max-md:max-w-full">
                Do you want to grow your audience?
              </div>
              <div className="mt-5 text-xl leading-8 text-white max-md:max-w-full">
                Check out the opt-in tools and make sure you have the most
                important ones turned on!
              </div>
              <Link
                to="/optin-tools"
                className="flex justify-center items-center self-start px-5 h-[48px] py-1.5 mt-8 text-lg font-medium leading-5 text-blue-800 bg-white rounded"
              >
                Let’s see the opt-in tools
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default YourAudience;
