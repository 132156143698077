const CalenderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.1846 3.49967C2.1846 2.85534 2.70694 2.33301 3.35127 2.33301H11.5179C12.1623 2.33301 12.6846 2.85534 12.6846 3.49967V11.6663C12.6846 12.3107 12.1623 12.833 11.5179 12.833H3.35127C2.70694 12.833 2.1846 12.3107 2.1846 11.6663V3.49967Z"
      stroke="#2B61AB"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.76793 1.16699V3.50033"
      stroke="#2B61AB"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.10127 1.16699V3.50033"
      stroke="#2B61AB"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.1846 5.83301H12.6846"
      stroke="#2B61AB"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CalenderIcon;
