import CustomTitle from "../RenderTableComponents/CustomTitle";

const columns = [
  {
    name: "Name",
    selector: (row) => row.flow_name,
    sortable: true,
    cell: (row, index) => (
      <CustomTitle
        title={row?.flow_name}
        description={""}
        index={index + 1}
        isDescriptionSpace={true}
      />
    ),
  },
];
export default columns;
