import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { forgotPassword } from "../../apis/Auth";
import Login from "../../assets/images/login.png";
import Logo from "../../assets/images/logo-dark.png";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import queryParamConstant from "../../utils/queryParamConstant";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");

  const { mutate: submitForgotPassword } = useMutation(
    queryParamConstant.FORGOT_PWD_URL,
    () =>
      forgotPassword({
        username: email,
      }),
    {
      enabled: !!email,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if (response) {
        }
      },
    }
  );

  const handleSubmit = () => {
    if (email !== "") {
      submitForgotPassword();
    } else {
      toast.error("Email field is required!", {
        toastId: "1",
      });
    }
  };
  return (
    <div className="flex justify-center items-center px-10 py-10 bg-slate-50 max-md:px-5">
      <div className="flex flex-col justify-center mt-10 max-w-full bg-white rounded-xl shadow-2x w-[900px]">
        <div className="pr-14 bg-white rounded-xl border-2 border-solid border-gray-300 max-md:pr-5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src={Login}
                alt="Login"
                className="grow w-full aspect-[0.72] max-md:mt-10 rounded-s-lg"
              />
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-sm font-bold leading-5 text-slate-800 max-md:mt-10 max-md:max-w-full">
                <div className="self-center text-[24px] leading-[28px] font-bold">
                  Forgot Your Password?
                </div>
                <img
                  loading="lazy"
                  src={Logo}
                  alt="Logo"
                  className="self-center mt-6 max-w-full aspect-[4.35] w-[132px]"
                />
                <div className="self-center my-10 text-[14px] leading-5 text-center text-zinc-600">
                  Forgot your password? No Problem. Just let us know your email
                  address and we will email you a password reset lnk.
                </div>
                <CustomInput
                  type="email"
                  title="Enter Your E-mail"
                  placeholder="Enter"
                  isPaddingZero
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="my-5"></div>
                <CustomButton
                  title="SUBMIT"
                  className="w-full"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
