const PermissionCheckBoxs = ({ roleNewList, setRoleNewList, title, item }) => {
  // const handleRoleRight = (id, key, value) => {
  //   const filterData = roleNewList?.find((item) => item.right_id === id)?.[key];
  // };
  return (
    <div>
      <div className="text-xl font-semibold leading-6 text-neutral-800 max-md:max-w-full">
        {title}
      </div>
      <div className="flex gap-5 mt-5 text-sm leading-5 whitespace-nowrap text-blue max-md:flex-wrap">
        <div className="flex gap-4">
          <input
            disabled={item?.is_view !== "1"}
            type="checkbox"
            value="View"
            // checked={item?.is_view === "1" ? true : false}
            // onChange={(e) =>
            //   handleRoleRight(item?.right_id, "is_view", e.target.value)
            // }
            className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
          />

          <label for="vehicle1">View</label>
        </div>
        <div className="flex gap-4">
          <input
            disabled={item?.is_show_create_chekbox !== "1"}
            type="checkbox"
            value="View"
            className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
          />
          <label for="vehicle1">Create</label>
        </div>
        <div className="flex gap-4">
          <input
            disabled={item?.is_show_update_chekbox !== "1"}
            type="checkbox"
            value="View"
            className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
          />

          <label for="vehicle1">Update</label>
        </div>
        <div className="flex gap-4">
          <input
            disabled={item?.is_show_delete_chekbox !== "1"}
            type="checkbox"
            value="View"
            className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
          />

          <label for="vehicle1">Delete</label>
        </div>
        <div className="flex gap-4">
          <input
            disabled={item?.is_show_export_chekbox !== "1"}
            type="checkbox"
            value="View"
            className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
          />

          <label for="vehicle1">Export</label>
        </div>
      </div>
      {item?.children.map((child) => (
        <div className="mt-8">
          <div className="text-xl font-semibold leading-6 text-neutral-800 max-md:max-w-full">
            {child?.form_detail}
          </div>
          <div className="flex gap-5 mt-5 text-sm leading-5 whitespace-nowrap text-blue max-md:flex-wrap">
            <div className="flex gap-4">
              <input
                disabled={child?.is_view !== "1"}
                type="checkbox"
                value="View"
                className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
              />

              <label for="vehicle1">View</label>
            </div>
            <div className="flex gap-4">
              <input
                disabled={child?.is_show_create_chekbox !== "1"}
                type="checkbox"
                value="View"
                className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
              />
              <label for="vehicle1">Create</label>
            </div>
            <div className="flex gap-4">
              <input
                disabled={child?.is_show_update_chekbox !== "1"}
                type="checkbox"
                value="View"
                className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
              />

              <label for="vehicle1">Update</label>
            </div>
            <div className="flex gap-4">
              <input
                disabled={child?.is_show_delete_chekbox !== "1"}
                type="checkbox"
                value="View"
                className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
              />

              <label for="vehicle1">Delete</label>
            </div>
            <div className="flex gap-4">
              <input
                disabled={child?.is_show_export_chekbox !== "1"}
                type="checkbox"
                value="View"
                className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
              />

              <label for="vehicle1">Export</label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default PermissionCheckBoxs;
