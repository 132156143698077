import CustomTitle from "../../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../../components/RenderTableComponents/NormalText";
import Status from "../../../components/RenderTableComponents/Status";
import { formatDate } from "../../../utils/formatDate";

const columns = [
  {
    name: "Name",
    selector: (row) => row?.uploaded_file_name,
    sortable: true,
    width: "30%",
    cell: (row) => (
      <CustomTitle title={row?.uploaded_file_name} description={""} />
    ),
  },
  {
    name: "Status",
    selector: (row) => row?.status,
    sortable: true,
    cell: (row) => row?.status && <Status row={row} />,
  },
  {
    name: "New Subscribers",
    selector: (row) => row?.new_subscribers,
    sortable: true,
    cell: (row) => <NormalText row={row?.new_subscribers} />,
  },
  {
    name: "Updated Subscribers",
    selector: (row) => row?.updated_subscribers,
    sortable: true,
    cell: (row) => <NormalText row={row?.updated_subscribers} />,
  },
  {
    name: "Processed rows",
    selector: (row) => row?.processed_rows,
    sortable: true,
    cell: (row) => (
      <NormalText row={`${row?.processed_rows} / ${row?.total_rows}`} />
    ),
  },
  {
    name: "Uploaded at",
    selector: (row) => row?.updated_at,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row?.updated_at)} />,
  },
];
export default columns;
