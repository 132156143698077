import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import Logo from "../../assets/images/logo-dark.png";
import Login from "../../assets/images/login.png";
import { useState } from "react";
import { useMutation } from "react-query";
import queryParamConstant from "../../utils/queryParamConstant";
import { toast } from "react-toastify";
import { getRoleRights, login } from "../../apis/Auth";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate: fetchRoleRights } = useMutation(
    queryParamConstant.LOGIN_URL,
    (token) => getRoleRights({ token: token }),
    {
      enabled: !!email || !!password,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if (response) {
          const data = JSON.stringify(response);
          localStorage.setItem("sms-data", data);
          navigate("/dashboard");
        }
      },
    }
  );

  const { mutate: submitLogin } = useMutation(
    queryParamConstant.LOGIN_URL,
    () =>
      login({
        username: email,
        password: password,
      }),
    {
      enabled: !!email || !!password,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if (response) {
          const token = response?.auth_token.toString();
          const token_type = response?.token_type.toString();
          localStorage.setItem("sms-token", `${token_type} ${token}`);
          fetchRoleRights(token);
        }
      },
    }
  );

  const handleSubmit = () => {
    if (email !== "" && password !== "") {
      submitLogin();
    } else {
      toast.error("All field is required!", {
        toastId: "1",
      });
    }
  };
  return (
    <div className="flex justify-center items-center px-10 py-10 bg-slate-50 max-md:px-5">
      <div className="flex flex-col justify-center mt-10 max-w-full bg-white rounded-xl shadow-2x w-[900px]">
        <div className="pr-14 bg-white rounded-xl border-2 border-solid border-gray-300 max-md:pr-5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                src={Login}
                alt="Login"
                className="grow w-full aspect-[0.72] max-md:mt-10 rounded-s-lg"
              />
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-sm font-bold leading-5 text-slate-800 max-md:mt-10 max-md:max-w-full">
                <div className="self-center text-[24px] leading-[28px] font-bold">
                  Log In
                </div>
                <img
                  loading="lazy"
                  src={Logo}
                  alt="Logo"
                  className="self-center my-6 max-w-full aspect-[4.35] w-[132px]"
                />
                <div className="my-2"></div>
                <CustomInput
                  type="email"
                  title="User Name"
                  placeholder="Enter"
                  value={email}
                  isPaddingZero
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="my-5"></div>
                <CustomInput
                  title="Password"
                  placeholder="Enter"
                  type="password"
                  value={password}
                  isPaddingZero
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="self-end mt-3 mb-5 leading-[20px] text-[14px] font-medium text-red-500 cursor-pointer"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </div>
                <CustomButton
                  title="Log In"
                  className="w-full"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
