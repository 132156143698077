import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../components/RenderTableComponents/NormalText";
import { icons } from "../../utils/Icon";
import formatNumber from "../../utils/formatNumber";

const columns = [
  {
    name: "Image",
    sortable: false,
    width: "5.2%",
    cell: (row) => (
      <div className="flex justify-center items-center ml-2">
        {icons(row.category_name)}
      </div>
    ),
  },
  {
    name: "Category",
    selector: (row) => row.category_name,
    sortable: true,
    width: "40%",
    cell: (row) => (
      <CustomTitle
        title={row?.category_name}
        description={row?.description}
        link={
          "/sequences/" + row?.category_name?.toLowerCase()?.replace(" ", "-")
        }
        id={row?.campaign_flow_id}
      />
    ),
  },
  {
    name: "Recipients",
    selector: (row) => row.recipients,
    sortable: true,
    cell: (row) => <NormalText row={formatNumber(row.recipients)} />,
  },

  {
    name: "Spent",
    selector: (row) => row.spent,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.spent)}`} />,
  },
  {
    name: "Sales",
    selector: (row) => row.sales,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.sales)}`} />,
  },
  {
    name: "RPM",
    selector: (row) => row.rpm,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.rpm)}`} />,
  },
  {
    name: "ROI",
    selector: (row) => row.roi,
    sortable: true,
    cell: (row) => <NormalText row={`${formatNumber(row.roi)}X`} />,
  },
];
export default columns;
