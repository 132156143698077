import BrowseAbdIcon from "../assets/icons/BrowseAbdIcon";
import CartAbdIcon from "../assets/icons/CartAbdIcon";
import CustomTriggersIcon from "../assets/icons/CustomTriggersIcon";
import FulfillNotificationIcon from "../assets/icons/FulfillNotificationIcon";
import HelpIcon from "../assets/icons/HelpIcon";
import HiIcon from "../assets/icons/HiIcon";
import OrderReceiptIcon from "../assets/icons/OrderReceiptIcon";
import ReceiptIcon from "../assets/icons/ReceiptIcon";
import SMSAbandonmentIcon from "../assets/icons/SMSAbandonmentIcon";
import SMSCampaignIcon from "../assets/icons/SMSCampaignIcon";

const icons = (heading) => {
  let icon = <HiIcon />;
  switch (heading) {
    case "Welcome":
      icon = <HiIcon />;
      break;
    case "Custom Triggers":
      icon = <CustomTriggersIcon />;
      break;
    case "Browse Abandonment":
      icon = <BrowseAbdIcon />;
      break;
    case "Abandoned Cart":
      icon = <CartAbdIcon />;
      break;
    case "Receipt":
      icon = <ReceiptIcon />;
      break;
    case "Fulfillment Notification":
      icon = <FulfillNotificationIcon />;
      break;
    case "Help":
      icon = <HelpIcon />;
      break;
    case "SMS Campaigns":
      icon = <SMSCampaignIcon />;
      break;
    case "SMS Abandonment":
      icon = <SMSAbandonmentIcon />;
      break;
    case "Order & Receipt":
      icon = <OrderReceiptIcon />;
      break;
    default:
      icon = <HiIcon />;
      break;
  }
  return icon;
};
export { icons };
