const SMSIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="36"
    viewBox="0 0 42 36"
    fill="none"
  >
    <path
      d="M0 31.2332C7.76263 29.7165 7.48996 18.0001 7.48996 18.0001C7.48996 8.47366 15.2185 0.745117 24.745 0.745117C34.2715 0.745117 42 8.47366 42 18.0001C42 27.5266 34.2715 35.2552 24.745 35.2552C19.4023 35.2552 14.6306 32.8267 11.4607 29.0093C6.67194 32.1365 0 31.2332 0 31.2332Z"
      fill="#2B61AB"
    />
    <path
      d="M16.0194 20.5657C16.4199 20.9747 16.9908 21.2048 17.5617 21.2048C17.5617 20.5998 17.5617 19.85 17.5617 19.0405C16.8346 19.0405 16.1075 19.0376 15.3804 19.032C15.3804 19.637 15.619 20.1738 16.0194 20.5657ZM15.3804 19.032C16.1075 19.0376 16.8346 19.0405 17.5617 19.0405C17.5617 18.3077 17.5617 17.5777 17.5617 16.8506C16.9908 16.8506 16.4199 17.0807 16.0194 17.4897C15.6104 17.8987 15.3804 18.4611 15.3804 19.032ZM17.5617 21.2048C18.1582 21.2048 18.7036 20.9577 19.0955 20.5657C19.4875 20.1738 19.7346 19.637 19.7346 19.032C19.1296 19.032 18.3798 19.0405 17.5617 19.0405C17.5617 19.85 17.5617 20.5998 17.5617 21.2048ZM17.5617 19.0405C18.3798 19.0405 19.1296 19.032 19.7346 19.032C19.7346 18.4611 19.5045 17.8987 19.0955 17.4897C18.7036 17.0977 18.1582 16.8506 17.5617 16.8506C17.5617 17.5777 17.5617 18.3077 17.5617 19.0405Z"
      fill="url(#paint0_linear_166_2715)"
    />
    <path
      d="M19.7346 18.001C19.7346 19.2024 18.7547 20.1824 17.5532 20.1824C16.3518 20.1824 15.3804 19.2024 15.3804 18.001C15.3804 16.7995 16.3518 15.8281 17.5532 15.8281C18.7547 15.8281 19.7346 16.7995 19.7346 18.001Z"
      fill="white"
    />
    <path
      d="M23.2027 20.5657C23.6117 20.9747 24.1741 21.2048 24.7535 21.2048C24.7535 20.5998 24.7535 19.85 24.7535 19.0405C24.0264 19.0405 23.2964 19.0376 22.5636 19.032C22.5721 19.637 22.8107 20.1738 23.2027 20.5657ZM22.5636 19.032C23.2964 19.0376 24.0264 19.0405 24.7535 19.0405C24.7535 18.3077 24.7535 17.5777 24.7535 16.8506C24.1741 16.8506 23.6117 17.0807 23.2027 17.4897C22.7937 17.8987 22.5636 18.4611 22.5636 19.032ZM24.7535 21.2048C25.35 21.2048 25.8953 20.9577 26.2873 20.5657C26.6792 20.1738 26.9178 19.637 26.9178 19.032C26.3214 19.032 25.563 19.0405 24.7535 19.0405C24.7535 19.85 24.7535 20.5998 24.7535 21.2048ZM24.7535 19.0405C25.563 19.0405 26.3214 19.032 26.9178 19.032C26.9264 18.4611 26.6878 17.8987 26.2873 17.4897C25.8953 17.0977 25.35 16.8506 24.7535 16.8506C24.7535 17.5777 24.7535 18.3077 24.7535 19.0405Z"
      fill="url(#paint1_linear_166_2715)"
    />
    <path
      d="M26.9178 18.001C26.9178 19.2024 25.9464 20.1824 24.745 20.1824C23.5435 20.1824 22.5636 19.2024 22.5636 18.001C22.5636 16.7995 23.5435 15.8281 24.745 15.8281C25.9464 15.8281 26.9178 16.7995 26.9178 18.001Z"
      fill="white"
    />
    <path
      d="M30.3944 20.5657C30.8034 20.9747 31.3658 21.2048 31.9367 21.2048C31.9367 20.5998 31.9452 19.85 31.9452 19.0405C31.2124 19.0405 30.4824 19.0376 29.7553 19.032C29.7553 19.637 30.0024 20.1738 30.3944 20.5657ZM29.7553 19.032C30.4824 19.0376 31.2124 19.0405 31.9452 19.0405C31.9452 18.3077 31.9424 17.5777 31.9367 16.8506C31.3658 16.8506 30.8034 17.0807 30.3944 17.4897C29.9854 17.8987 29.7553 18.4611 29.7553 19.032ZM31.9367 21.2048C32.5417 21.2048 33.0785 20.9577 33.4705 20.5657C33.8624 20.1738 34.1095 19.637 34.1095 19.032C33.5046 19.032 32.7547 19.0405 31.9452 19.0405C31.9452 19.85 31.9367 20.5998 31.9367 21.2048ZM31.9452 19.0405C32.7547 19.0405 33.5046 19.032 34.1095 19.032C34.1095 18.4611 33.8795 17.8987 33.4705 17.4897C33.0785 17.0977 32.5417 16.8506 31.9367 16.8506C31.9424 17.5777 31.9452 18.3077 31.9452 19.0405Z"
      fill="url(#paint2_linear_166_2715)"
    />
    <path
      d="M34.1095 18.001C34.1095 19.2024 33.1381 20.1824 31.9282 20.1824C30.7267 20.1824 29.7553 19.2024 29.7553 18.001C29.7553 16.7995 30.7267 15.8281 31.9282 15.8281C33.1381 15.8281 34.1095 16.7995 34.1095 18.001Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_166_2715"
        x1="16.017"
        y1="20.568"
        x2="17.5659"
        y2="19.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#C248EB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_166_2715"
        x1="23.2052"
        y1="20.568"
        x2="24.7542"
        y2="19.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#C248EB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_166_2715"
        x1="30.3934"
        y1="20.568"
        x2="31.9424"
        y2="19.038"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#C248EB" />
      </linearGradient>
    </defs>
  </svg>
);
export default SMSIcon;
