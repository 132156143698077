import { toast } from "react-toastify";
import {
  GET_ROLES_URL,
  GET_TERM_TYPES_URL,
  GET_RIGHTS_URL,
  ADD_ROLE_RIGHTS_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const getRoleUrl = (body) =>
  dataService
    .post(GET_ROLES_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getTermsTypes = (body) =>
  dataService
    .post(GET_TERM_TYPES_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getRight = (body) =>
  dataService
    .post(GET_RIGHTS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const addRoleRight = (body) =>
  dataService
    .post(ADD_ROLE_RIGHTS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
export { getRoleUrl, addRoleRight, getTermsTypes, getRight };
