const DownArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      d="M11.5179 5.25L7.43459 9.33333L3.35126 5.25"
      stroke="#5C5C5C"
      strokeWidth="1.2895"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownArrowIcon;
