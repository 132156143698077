const OrderReceiptIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    className="w-6"
  >
    <g>
      <path
        d="M488.5 252.001h-50V229.6c0-27.481-19.178-50.611-45-57.026V15c0-8.284-6.716-15-15-15h-355c-8.284 0-15 6.716-15 15v411c0 8.284 6.716 15 15 15h231v12.292c0 32.372 26.378 58.708 58.8 58.708h131.4c32.422 0 58.8-26.336 58.8-58.708V267.001c0-8.284-6.716-15-15-15zm-80-22.401v22.401h-59V229.6c0-15.88 13.233-28.8 29.5-28.8s29.5 12.919 29.5 28.8zM38.5 411V30h325v142.836c-25.315 6.758-44 29.639-44 56.763V252h-50c-8.284 0-15 6.716-15 15v144zm435 42.292c0 15.83-12.92 28.708-28.8 28.708H313.3c-15.88 0-28.8-12.878-28.8-28.708V282.001h35V321.5c0 8.284 6.716 15 15 15s15-6.716 15-15v-39.499h59V321.5c0 8.284 6.716 15 15 15s15-6.716 15-15v-39.499h35z"
        fill="#0375ff"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M113.381 64.779 96.333 83.092l-1.141-1.419c-5.19-6.457-14.632-7.486-21.089-2.295-6.457 5.189-7.485 14.631-2.296 21.088l12 14.931a15.003 15.003 0 0 0 22.671.825l28.859-31c5.646-6.063 5.306-15.555-.758-21.2-6.06-5.645-15.553-5.306-21.198.757zM184.5 105.5h133.999c8.284 0 15-6.716 15-15s-6.716-15-15-15H184.5c-8.284 0-15 6.716-15 15s6.716 15 15 15zM113.381 155.779l-17.048 18.313-1.141-1.419c-5.19-6.457-14.632-7.485-21.089-2.295s-7.485 14.631-2.296 21.088l12 14.931a15.003 15.003 0 0 0 22.671.825l28.859-31c5.646-6.063 5.306-15.555-.758-21.2-6.06-5.645-15.553-5.306-21.198.757zM184.5 196.5h83.998c8.284 0 15-6.716 15-15s-6.716-15-15-15H184.5c-8.284 0-15 6.716-15 15s6.716 15 15 15zM113.381 246.779l-17.048 18.313-1.141-1.419c-5.19-6.458-14.632-7.485-21.089-2.295-6.457 5.189-7.485 14.631-2.296 21.088l12 14.931a15.003 15.003 0 0 0 22.671.825l28.859-31c5.646-6.063 5.306-15.555-.758-21.2-6.06-5.645-15.553-5.306-21.198.757zM209.497 257.5H184.5c-8.284 0-15 6.716-15 15s6.716 15 15 15h24.997c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
        fill="#0375ff"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);
export default OrderReceiptIcon;
