import { formatDate } from "../../utils/formatDate";
import CustomTitle from "../RenderTableComponents/CustomTitle";

const columns = [
  {
    name: "Name",
    selector: (row) => row.flow_name,
    sortable: true,
    cell: (row, index) => (
      <CustomTitle
        index={index + 1}
        title={row?.flow_name}
        description={formatDate(row?.updated_at)}
      />
    ),
  },
];
export default columns;
