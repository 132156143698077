const CustomSelect = ({
  title,
  isDefault = true,
  options,
  isPaddingZero = false,
  ...props
}) => {
  return (
    <div
      className={`flex flex-col flex-1 max-md:px-5 max-md:max-w-full ${
        isPaddingZero ? "px-0" : "px-10"
      }`}
    >
      <div className="font-bold text-blue max-md:max-w-full">{title}</div>
      <select
        className="flex gap-2.5 px-2.5 py-2.5 mt-1 rounded border border-gray-light border-solid text-neutral-500 max-md:flex-wrap"
        {...props}
      >
        {title && <option hidden={!isDefault}>Select {title}</option>}
        {options &&
          options.map((item) => (
            <option key={item?.title}>{item?.title}</option>
          ))}
      </select>
    </div>
  );
};
export default CustomSelect;
