const CustomTriggersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    className="w-6"
  >
    <g>
      <circle
        cx="256"
        cy="196"
        r="30"
        fill="none"
        stroke="#0375ff"
        strokeWidth="30"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></circle>
      <path
        d="M316 316H196c0-33.138 26.861-60 60-60 33.137 0 60 26.862 60 60z"
        fill="none"
        stroke="#0375ff"
        strokeWidth="30"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M406 256c0 30.518-9.269 58.868-24.988 82.584l64.418 64.418C477.012 362.364 497 311.452 497 256s-19.988-106.364-51.57-147.003l-64.42 64.418C396.729 197.13 406 225.482 406 256z"
        fill="none"
        stroke="#0375ff"
        strokeWidth="30"
        strokeLinecap="square"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M338.584 381.012C314.87 396.731 286.518 406 256 406c-82.705 0-150-67.295-150-150s67.295-150 150-150c30.518 0 58.868 9.269 82.584 24.988l64.418-64.418C362.364 34.988 311.452 15 256 15 123.452 15 15 123.452 15 256s108.452 241 241 241c55.452 0 106.364-19.99 147.003-51.572l-64.419-64.416z"
        fill="none"
        stroke="#0375ff"
        strokeWidth="30"
        strokeLinecap="square"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="m86.294 86.294 63.692 63.692M86.294 425.706l63.692-63.692"
        fill="none"
        stroke="#0375ff"
        strokeWidth="30"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);
export default CustomTriggersIcon;
