import { toast } from "react-toastify";
import { GET_REVENUE_REPORT_URL } from "../../config/Api";
import { dataService } from "../../config/DataService";

const getRevenueReport = (body) =>
  dataService
    .post(GET_REVENUE_REPORT_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

export { getRevenueReport };
