import { useQuery } from "react-query";
import { getBilling } from "../../apis/Billing";
import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";

const BillingPage = () => {
  const { data, error, isLoading } = useQuery(
    queryParamConstant.GET_SMS_USAGE_URL,
    () => getBilling(),
    { refetchOnWindowFocus: false }
  );

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-2">
      <div className="text-[18px] leading-[28px] font-semibold text-blue m-6 ">
        SMS Usage
      </div>
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isFilterLayout={false}
          tableData={data?.list}
          columns={columns}
          isSearch={false}
          isFilter={false}
        />
      )}
    </div>
  );
};
export default BillingPage;
