import { useNavigate } from "react-router-dom";

const CustomTitle = ({
  title,
  index,
  description,
  isDescriptionSpace = false,
  link,
  isDescription = true,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-center"
      onClick={() =>
        link &&
        navigate(link, {
          state: {
            id: id,
            name: title || "",
            description: (isDescription && description) || "",
          },
        })
      }
    >
      {index && (
        <div
          className={`rounded-full w-[36px] h-[36px] mr-5 flex justify-center items-center text-[14px] leading-[22px] font-semibold ${
            index === 1
              ? "bg-light-sky text-dark-sky"
              : index === 2
              ? "bg-light-purple text-dark-purple"
              : "bg-light-pink text-dark-pink"
          }`}
        >
          0{index}
        </div>
      )}
      <div className="flex flex-col">
        <div className="text-[14px] leading-[20px] font-medium text-blue">
          {title}
        </div>
        {description ? (
          <div className="text-[14px] leading-[22px] font-normal text-zinc-600">
            {description}
          </div>
        ) : (
          isDescriptionSpace && <div className="mt-3 mb-2.5"></div>
        )}
      </div>
    </div>
  );
};

export default CustomTitle;
