const BASE_URL = "api/v1/backend";

// ? Auth APIs
const LOGIN_EP = "/login";
const LOGOUT_EP = "/logout";
const FORGOT_PWD_EP = "/send_reset_pass_link";
const GET_ROLE_RIGHTS_EP = "/get_user_role_rights";
const CHECK_RESET_EXP_EP = "/check_reset_token";
const RESET_PWD_EP = "/reset_password";
const CHANGE_PWD_EP = "/change_password";

export const LOGIN_URL = BASE_URL + LOGIN_EP;
export const LOGOUT_URL = BASE_URL + LOGOUT_EP;
export const FORGOT_PWD_URL = BASE_URL + FORGOT_PWD_EP;
export const GET_ROLE_RIGHTS_URL = BASE_URL + GET_ROLE_RIGHTS_EP;
export const CHECK_RESET_EXP_URL = BASE_URL + CHECK_RESET_EXP_EP;
export const RESET_PWD_URL = BASE_URL + RESET_PWD_EP;
export const CHANGE_PWD_URL = BASE_URL + CHANGE_PWD_EP;

// ? Optin APIs
const GET_OPTIN_DATA_EP = "/get_all_optins";

export const GET_OPTIN_DATA_URL = BASE_URL + GET_OPTIN_DATA_EP;

// ? Subscriber APIs
const GET_SUBSCRIBERS_OVERVIEW_EP = "/overall_subscribers_added";
const GET_SUBSCRIBERS_LIST_EP = "/list_all_subscribers";
const IMPORT_SUBSCRIBERS_EP = "/import_csv";
const GET_IMPORTED_FILES_LIST_EP = "/get_import_subscribers";
const GET_SUBSCRIBER_INFO_EP = "/subscribers_info";
const GET_SUBSCRIBER_LIST_AND_SEGMENTS_EP = "/subscribers_segment_list";
const GET_SUBSCRIPTION_HISTORY_EP = "/subscriber_history";
const GET_SUBSCRIBERS_CHART_DATA_EP = "/get_subscribers_chart_details";
const UNSUB_USER_EP = "/unsubscribe_user";

export const GET_SUBSCRIBERS_OVERVIEW_URL =
  BASE_URL + GET_SUBSCRIBERS_OVERVIEW_EP;
export const GET_SUBSCRIBERS_LIST_URL = BASE_URL + GET_SUBSCRIBERS_LIST_EP;
export const IMPORT_SUBSCRIBERS_URL = BASE_URL + IMPORT_SUBSCRIBERS_EP;
export const GET_IMPORTED_FILES_LIST_URL =
  BASE_URL + GET_IMPORTED_FILES_LIST_EP;
export const GET_SUBSCRIBER_INFO_URL = BASE_URL + GET_SUBSCRIBER_INFO_EP;
export const GET_SUBSCRIBER_LIST_AND_SEGMENTS_URL =
  BASE_URL + GET_SUBSCRIBER_LIST_AND_SEGMENTS_EP;
export const GET_SUBSCRIPTION_HISTORY_URL =
  BASE_URL + GET_SUBSCRIPTION_HISTORY_EP;
export const GET_SUBSCRIBERS_CHART_DATA_URL =
  BASE_URL + GET_SUBSCRIBERS_CHART_DATA_EP;
export const UNSUB_USER_URL = BASE_URL + UNSUB_USER_EP;

// ? Automated Flows APIs
const GET_AUTOMATED_FLOWS_OVERVIEW_EP = "/get_automated_flows";
const GET_AUTOMATED_FLOWS_LIST_EP = "/get_automated_flow_details_list";

export const GET_AUTOMATED_FLOWS_OVERVIEW_URL =
  BASE_URL + GET_AUTOMATED_FLOWS_OVERVIEW_EP;
export const GET_AUTOMATED_FLOWS_LIST_URL =
  BASE_URL + GET_AUTOMATED_FLOWS_LIST_EP;

// ? Campaign Flows APIs
const GET_CAMPAIGN_FLOWS_OVERVIEW_EP = "/get_campaign_flows";
const GET_CAMPAIGN_FLOWS_LIST_EP = "/get_single_campaign_flows";

export const GET_CAMPAIGN_FLOWS_OVERVIEW_URL =
  BASE_URL + GET_CAMPAIGN_FLOWS_OVERVIEW_EP;
export const GET_CAMPAIGN_FLOWS_LIST_URL =
  BASE_URL + GET_CAMPAIGN_FLOWS_LIST_EP;

// ? Inbox APIs
const GET_ALL_CHATS_EP = "/get_all_subscribers_chat";
const GET_CHAT_MSGS_EP = "/get_subscriber_messages";
const SENG_MSG_EP = "/send_message_to_subscriber";

export const GET_ALL_CHATS_URL = BASE_URL + GET_ALL_CHATS_EP;
export const GET_CHAT_MSGS_URL = BASE_URL + GET_CHAT_MSGS_EP;
export const SENG_MSG_URL = BASE_URL + SENG_MSG_EP;

// ? Segments & lists APIs
const GET_SEGMENTS_LIST_EP = "/get_all_segment_list";
const GET_SEGMENT_SUBSCRIBERS_EP = "/get_segment_subscribers";
const GET_SEGMENT_DETAILS_EP = "/get_segment_details";
const IMPORT_SEGMENT_SUBSCRIBERS_EP = "/import_segment_subscribers";
const GET_SEGMENT_CAMPAIGNS_EP = "/get_campaigns_of_segment";

export const GET_SEGMENTS_LIST_URL = BASE_URL + GET_SEGMENTS_LIST_EP;
export const GET_SEGMENT_SUBSCRIBERS_URL =
  BASE_URL + GET_SEGMENT_SUBSCRIBERS_EP;
export const GET_SEGMENT_DETAILS_URL = BASE_URL + GET_SEGMENT_DETAILS_EP;
export const IMPORT_SEGMENT_SUBSCRIBERS_URL =
  BASE_URL + IMPORT_SEGMENT_SUBSCRIBERS_EP;
export const GET_SEGMENT_CAMPAIGNS_URL = BASE_URL + GET_SEGMENT_CAMPAIGNS_EP;

// ? Reports APIs
const GET_REVENUE_REPORT_EP = "/get_revenue_report";

export const GET_REVENUE_REPORT_URL = BASE_URL + GET_REVENUE_REPORT_EP;

// ? Billing APIs
const GET_SMS_USAGE_EP = "/get_sms_usage";

export const GET_SMS_USAGE_URL = BASE_URL + GET_SMS_USAGE_EP;

// ? Terms Updation APIs
const GET_TERMS_EP = "/get_terms";
const GET_TERM_DETAIL_EP = "/get_term_detail";
const ADD_UPDATE_TERM_EP = "/add_update_term_detail";
const GET_TERM_TYPES_EP = "/get_category_types_options";

export const GET_TERMS_URL = BASE_URL + GET_TERMS_EP;
export const GET_TERM_DETAIL_URL = BASE_URL + GET_TERM_DETAIL_EP;
export const ADD_UPDATE_TERM_URL = BASE_URL + ADD_UPDATE_TERM_EP;
export const GET_TERM_TYPES_URL = BASE_URL + GET_TERM_TYPES_EP;

// ? Right APIs
const GET_RIGHTS_EP = "/get_rights";
const GET_ROLES_EP = "/get_roles";
const ADD_ROLE_RIGHTS_EP = "/add_role_and_rights";
const UPDATE_ROLE_RIGHTS_EP = "/update_role_and_rights";
const GET_ROLE_SPECIFIC_RIGHTS_EP = "/get_role_rights_details";

export const GET_RIGHTS_URL = BASE_URL + GET_RIGHTS_EP;
export const ADD_ROLE_RIGHTS_URL = BASE_URL + ADD_ROLE_RIGHTS_EP;
export const UPDATE_ROLE_RIGHTS_URL = BASE_URL + UPDATE_ROLE_RIGHTS_EP;
export const GET_ROLES_URL = BASE_URL + GET_ROLES_EP;
export const GET_ROLE_SPECIFIC_RIGHTS_URL =
  BASE_URL + GET_ROLE_SPECIFIC_RIGHTS_EP;

// ? Settings APIs
const GET_ALL_SETTINGS_EP = "/get_all_settings";
const GET_TIMEZONE_OPTIONS_EP = "/timezone_options";
const UPDATE_SITE_SETTINGS_EP = "/update_site_setting";
const UPDATE_CONTACT_INFO_EP = "/update_contact_info_setting";
const UPDATE_COMPANY_INFO_EP = "/update_company_info_setting";
const UPDATE_PRIVACY_LINKS_EP = "/update_term_privacy_links";
const UPDATE_SENDING_SETTINGS_EP = "/update_sending_setting";
const UPDATE_CONTACT_CARD_EP = "/update_contact_card_setting";

export const GET_ALL_SETTINGS_URL = BASE_URL + GET_ALL_SETTINGS_EP;
export const GET_TIMEZONE_OPTIONS_URL = BASE_URL + GET_TIMEZONE_OPTIONS_EP;
export const UPDATE_SITE_SETTINGS_URL = BASE_URL + UPDATE_SITE_SETTINGS_EP;
export const UPDATE_CONTACT_INFO_URL = BASE_URL + UPDATE_CONTACT_INFO_EP;
export const UPDATE_COMPANY_INFO_URL = BASE_URL + UPDATE_COMPANY_INFO_EP;
export const UPDATE_PRIVACY_LINKS_URL = BASE_URL + UPDATE_PRIVACY_LINKS_EP;
export const UPDATE_SENDING_SETTINGS_URL =
  BASE_URL + UPDATE_SENDING_SETTINGS_EP;
export const UPDATE_CONTACT_CARD_URL = BASE_URL + UPDATE_CONTACT_CARD_EP;
