const CustomLightButton = ({
  title = "Export",
  isEnd = true,
  className,
  ...props
}) => {
  return (
    <button
      className={`flex justify-center items-center font-medium whitespace-nowrap text-blue-800 rounded border border-blue-800 border-solid px-5 leading-[157%] max-md:mr-2.5 h-[48px] ${
        className && className
      } ${isEnd ? "self-end" : ""}`}
      {...props}
    >
      {title}
    </button>
  );
};
export default CustomLightButton;
