import { useState } from "react";
import { useQuery } from "react-query";
import { overallSubscribersAdded } from "../../apis/Subscribers";
import DownIcon from "../../assets/icons/DownIcon";
import InformIcon from "../../assets/icons/InfomIcon";
import UpIcon from "../../assets/icons/UpIcon";
import CustomButton from "../../components/CustomButton";
import CustomLoader from "../../components/CustomLoader";
import CustomTabs from "../../components/CustomTabs";
import queryParamConstant from "../../utils/queryParamConstant";
import ImportSubscribers from "./ImportSubscribers";
import Overview from "./Overview";
import Profiles from "./Profiles";
import YourAudience from "./YourAudience";
import { useNavigate } from "react-router-dom";

const SubscribersPage = ({ index }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(index || 0);
  const [searchFilter, onSearchFilter] = useState("");
  const { data, error, isLoading } = useQuery(
    queryParamConstant.GET_SUBSCRIBERS_OVERVIEW_URL,
    () => overallSubscribersAdded({}),
    { refetchOnWindowFocus: false }
  );

  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="relative rounded-xl pb-10">
      <div className="relative flex justify-between w-full px-12 bg-white rounded-xl border border-solid shadow-lg border-gray-light max-md:px-5">
        {isLoading ? (
          <div className=" h-[120px] ">
            <CustomLoader />
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center items-start py-6 w-[33%]">
              <div className="text-[34px] leading-[43.16px] font-bold text-blue">
                {data?.subscribers_count}
              </div>
              <div className="flex items-center gap-2 text-[18px] leading-[30.83px] font-normal text-gray-text">
                Subscribers{" "}
                <span data-tooltip-id="segment-Subscribers">
                  <InformIcon />
                </span>
              </div>
            </div>
            <div className="w-[1px] border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
            <div className="flex flex-col justify-center items-center py-6 w-[33%]">
              <div className="flex flex-col items-start justify-start">
                <div className="flex items-start justify-start">
                  <div>
                    <UpIcon />
                    <DownIcon />
                  </div>
                  <div className="text-[34px] leading-[43.16px] font-bold text-blue ml-3">
                    {data?.overall_weekly_subscribers}%
                  </div>
                </div>
                <div className="text-[18px] leading-[30.83px] font-normal text-gray-text">
                  over last 7 days
                </div>
              </div>
            </div>
            <div className="w-[1px] border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
            <div className="flex flex-col justify-center items-center py-6 w-[33%]">
              <div className="flex flex-col items-start justify-start">
                <div className="flex items-start justify-start">
                  <div>
                    <UpIcon />
                    <DownIcon />
                  </div>
                  <div className="text-[34px] leading-[43.16px] font-bold text-blue ml-3">
                    {data?.overall_monthly_subscribers}%
                  </div>
                </div>
                <div className="text-[18px] leading-[30.83px] font-normal text-gray-text">
                  over last 30 days
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col ">
        <div className="flex bg-white  items-start px-2 my-5 pt-5 pb-4 max-md:px-5 rounded-xl">
          <CustomTabs
            active={active}
            onClick={(index) => {
              setActive(index);
              navigate(
                index === 0
                  ? "/subscribers/overview"
                  : index === 1
                  ? "/subscribers/profiles"
                  : index === 2
                  ? "/subscribers/import-subscribers"
                  : "/subscribers/audience"
              );
              onSearchFilter("");
            }}
            tabsList={[
              { id: 1, title: "Overview" },
              { id: 2, title: "Profiles" },
              { id: 3, title: "Import subscribers" },
              { id: 4, title: "Do you want to grow your audience?" },
            ]}
          />
          {active !== 0 && (
            <div className="flex flex-row flex-1 justify-end items-center">
              {" "}
              {/* <CustomSortFilter
                selected={sortFilterData}
                onChange={(value) => {
                  setSortFilterData(value);
                }}
                options={campaignFlowsFilter}
              /> */}
              {active === 1 && (
                <>
                  <span data-tooltip-id="search-member">
                    <InformIcon />
                  </span>
                  <div className="flex flex-col mx-3 py-px text-sm whitespace-nowrap bg-white text-neutral-500">
                    <div className="relative flex items-center ">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c725e12ba0bda12d49b671469b893e3766bf88f5d15c7fadda99f8e08f3d5929?"
                        className="shrink-0 aspect-[1.06] fill-neutral-500 w-[17px] absolute ml-3"
                        alt=""
                      />
                      <input
                        value={searchFilter}
                        onChange={(e) => onSearchFilter(e.target.value)}
                        className="flex gap-2.5 pl-10 pr-3.5 py-2.5 rounded border border-gray-light border-solid w-[260px]"
                        placeholder="Search..."
                      />
                    </div>
                  </div>{" "}
                </>
              )}
              {active === 2 && (
                <CustomButton title={"Import CSV"} className="px-8" />
              )}
            </div>
          )}
        </div>
        {active === 0 ? (
          <Overview />
        ) : active === 1 ? (
          <Profiles searchFilter={searchFilter} />
        ) : active === 2 ? (
          <ImportSubscribers searchFilter={searchFilter} />
        ) : (
          <YourAudience />
        )}
      </div>
    </div>
  );
};
export default SubscribersPage;
