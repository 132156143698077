import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { listAllSubscribers } from "../../../apis/Subscribers";
import CustomLoader from "../../../components/CustomLoader";
import RenderTable from "../../../components/RenderTable";
import queryParamConstant from "../../../utils/queryParamConstant";
import columns from "./DatatableConfig";
import { useNavigate } from "react-router-dom";

const Profiles = ({ searchFilter }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_SUBSCRIBERS_LIST_URL,
    () =>
      listAllSubscribers({
        filter: {
          search_text: searchFilter,
          popup_type: "",
          status: "",
          device: "",
          data_range: "all",
          start_date: "",
          end_date: "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );
  useEffect(() => {
    if (searchFilter) {
      setTimeout(() => refetch(), 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading || isFetching ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          refetch={refetch}
          totalRows={data?.total}
          page={page}
          searchData={searchFilter}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={columns}
          isFilterLayout={false}
          isFilter={false}
          setAllDataList={setAllDataList}
          onRowClicked={(row) =>
            navigate("/subscribers/" + row?.subscriber_id, {
              state: {
                id: row?.subscriber_id,
                name: row?.phone_no,
              },
            })
          }
        />
      )}
    </div>
  );
};
export default Profiles;
