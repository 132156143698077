/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "react-query";
import LineChart from "../../components/LineChart";
import RecentCampaignFlows from "../../components/RecentCampaignFlows";
import TopAutomatedFlows from "../../components/TopAutomatedFlows";
import TopPerformingTools from "../../components/TopPerformingTools";
import queryParamConstant from "../../utils/queryParamConstant";
import { getSubscribersChartDetails } from "../../apis/Home";
import CustomLoader from "../../components/CustomLoader";
import { useEffect, useState } from "react";
import moment from "moment";

const HomePage = () => {
  const [selectedRange, setSelectedRange] = useState("this_month");
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [dateRange, setDateRange] = useState();
  const [dateRangeValue, setDateRangeValue] = useState([
    startDateRange,
    endDateRange,
  ]);
  const { data: lastMonth, isLoading: isLoadingLast } = useQuery(
    queryParamConstant.GET_SUBSCRIBERS_CHART_DATA_URL_HOME_LAST,
    () =>
      getSubscribersChartDetails({
        filter: {
          data_range: "thirty_days",
          start_date: "",
          end_date: "",
        },
        range_type: "daily",
        subscribers_chart_filter: "new_subscribers",
      }),
    { refetchOnWindowFocus: false }
  );
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_SUBSCRIBERS_CHART_DATA_URL_HOME,
    () =>
      getSubscribersChartDetails({
        filter: {
          data_range: selectedRange,
          start_date:
            selectedRange === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            selectedRange === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        range_type: "daily",
        subscribers_chart_filter: "new_subscribers",
      }),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    if (startDateRange && endDateRange) {
      setTimeout(() => refetch(), 10);
    }
  }, [startDateRange, endDateRange]);
  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="relative">
      <div className="relative">
        {isLoading || isLoadingLast ? (
          <div className="h-[300px] bg-white">
            <CustomLoader />
          </div>
        ) : (
          <LineChart
            lastMonth={lastMonth}
            dateRange={dateRange}
            setDateRangeValue={setDateRangeValue}
            setStartDateRange={setStartDateRange}
            setEndDateRange={setEndDateRange}
            dateRangeValue={dateRangeValue}
            setDateRange={setDateRange}
            isLoading={isLoading || isFetching}
            lineData={data}
            isMonthFilter={false}
            filterSelection={selectedRange}
            onFilterChange={(item) => {
              if (item !== "custom") {
                setSelectedRange(item);
                setTimeout(() => refetch(), 10);
              } else {
                setSelectedRange(item);
              }
            }}
          />
        )}
      </div>
      <div className="flex lg:mt-7 max-lg:flex-col gap-5 justify-between mb-10">
        <RecentCampaignFlows />
        <TopAutomatedFlows />
        <TopPerformingTools />
      </div>
    </div>
  );
};
export default HomePage;
