import { toast } from "react-toastify";
import {
  GET_ALL_SETTINGS_URL,
  GET_TIMEZONE_OPTIONS_URL,
  UPDATE_CONTACT_CARD_URL,
  UPDATE_CONTACT_INFO_URL,
  UPDATE_PRIVACY_LINKS_URL,
  UPDATE_SENDING_SETTINGS_URL,
  UPDATE_SITE_SETTINGS_URL,
  UPDATE_COMPANY_INFO_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const getAllSettings = (body) =>
  dataService
    .post(GET_ALL_SETTINGS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const timezoneOptions = (body) =>
  dataService
    .post(GET_TIMEZONE_OPTIONS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const UpdateSiteSetting = (body) =>
  dataService
    .post(UPDATE_SITE_SETTINGS_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const UpdateContactInfoSetting = (body) =>
  dataService
    .post(UPDATE_CONTACT_INFO_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const updatePrivacyLinks = (body) =>
  dataService
    .post(UPDATE_PRIVACY_LINKS_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const updateSendingSettingLinks = (body) =>
  dataService
    .post(UPDATE_SENDING_SETTINGS_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const updateContactCardLinks = (body) =>
  dataService
    .post(UPDATE_CONTACT_CARD_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const updateCompanyDetails = (body) =>
  dataService
    .post(UPDATE_COMPANY_INFO_URL, body)
    .then(({ data }) => {
      if (data?.status === 1) {
        toast.success(data?.message, {
          toastId: "1",
        });
        return data?.data;
      } else {
        toast.error(data?.message, {
          toastId: "1",
        });
        throw data;
      }
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

export {
  UpdateContactInfoSetting,
  UpdateSiteSetting,
  getAllSettings,
  timezoneOptions,
  updateContactCardLinks,
  updatePrivacyLinks,
  updateSendingSettingLinks,
  updateCompanyDetails,
};
