const LayoutIcon = () => (
  <svg
    className="shrink-0 w-5 mr-2"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="#E9EFF4"></rect>
    <rect
      x="5.333"
      y="8.017"
      width="21.333"
      height="3.2"
      rx="1.6"
      fill="#CED5E1"
    ></rect>
    <rect
      x="5.333"
      y="14.4"
      width="21.333"
      height="3.2"
      rx="1.6"
      fill="#CED5E1"
    ></rect>
    <rect
      x="5.333"
      y="20.783"
      width="10.667"
      height="3.2"
      rx="1.6"
      fill="#CED5E1"
    ></rect>
  </svg>
);
export default LayoutIcon;
