const Config = {
  grid: {
    show: true,
    left: "0.2%",
    right: "0.5%",
    bottom: "0%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: [
      "oct 1, 2022",
      "oct 10, 2022",
      "oct 20, 2022",
      "oct 30, 2022",
      "Nov 01, 2022",
      "Nov 10, 2022",
      "Nov 20, 2022",
    ],
    axisLine: {
      show: false,
    },
    axisTick: {
      // Be careful: it's axisTick and not axisTicks
      show: false,
    },
  },
  yAxis: {
    type: "value",
    min: 5000,
    max: 20000,
    splitNumber: 4,
    axisLine: {
      show: false,
    },
    axisTick: {
      // Be careful: it's axisTick and not axisTicks
      show: false,
    },
  },
  tooltip: {
    trigger: "axis",
    padding: 10,
    position: "top",
    extraCssText:
      "box-shadow: 0px 8px 16px 0px #3232470f; border-radius: 20px;",
    backgroundColor: "#FFFFFF",
    className: "tooltip-chart",
    formatter(params) {
      return params.length === 1
        ? `<div style="position:relative" ><span style="font-size:12px; font-weight:400;">SMS</span> </br> <span style="font-size:16px; color:#11263C; font-weight:700;">${params[0]?.value}</span> </br> <span style="font-size:12px; font-weight:400;">${params[0]?.axisValue}</span><span class="triangle"></span></div>`
        : `<div style="position:relative" ><span style="font-size:12px; font-weight:400;">SMS</span> </br> ${params[0]?.marker}<span style="font-size:16px; color:#11263C; font-weight:700;"> ${params[0]?.value}</span> 
        </br>${params[1]?.marker}<span style="font-size:16px; color:#11263C; font-weight:700;"> ${params[1]?.value}</span>
       </br> <span style="font-size:12px; font-weight:400;">${params[0]?.axisValue}</span><span class="triangle"></span></div>`;
    },
  },
  series: [
    {
      data: [8200, 9320, 9010, 9340, 12900, 13300, 13200],
      type: "line",
      width: "4px",
      color: "#2B61AB",
      smooth: true,
    },
    {
      data: [8800, 18820, 9810, 9340, 13900, 15300, 19200],
      type: "line",
      width: "4px",
      color: "#D6E4F5",
      smooth: true,
    },
  ],
};
export default Config;
