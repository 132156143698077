const BrowseAbdIcon = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 30h32v4H2.376C1.616 34 1 33.204 1 32.222V30z"
      fill="#ECF4FF"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M39 32v-2H7v2c0 1.105.699 2 1.561 2h28.878c.862 0 1.561-.895 1.561-2z"
      fill="#ECF4FF"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.382 9.618h28.327c.763 0 1.382.619 1.382 1.382v19H1V11c0-.763.619-1.382 1.382-1.382z"
      fill="#fff"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M15.286 22.572a4.286 4.286 0 100-8.572 4.286 4.286 0 000 8.572zM18.714 21.714L23 26"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M33 14a6 6 0 100-12 6 6 0 000 12z"
      fill="#E0F2FF"
      stroke="#0375FF"
      strokeWidth="2"
    ></path>
    <path
      clipRule="evenodd"
      d="M30.379 5.879c.244-.452.372-.5.5-.5s.256.048.353.146L33 7.292l1.769-1.767a.498.498 0 01.853.354.498.498 0 01-.146.353l-1.769 1.767 1.77 1.769a.498.498 0 01-.355.853.498.498 0 01-.353-.146l-1.769-1.769-1.767 1.769a.498.498 0 01-.853-.354c0-.128.049-.256.146-.353l1.767-1.769-1.767-1.767a.499.499 0 01-.146-.353z"
      stroke="#0375FF"
    ></path>
  </svg>
);
export default BrowseAbdIcon;
