import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import "./assets/css/custom.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import CustomRoutes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import CustomTooltip from "./components/CustomTooltip/CustomTooltip";
import "react-loading-skeleton/dist/skeleton.css";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <CustomRoutes />
    <ToastContainer autoClose={1000} />
    <CustomTooltip />
  </QueryClientProvider>
);

export default App;
