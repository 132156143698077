const SMSCampaignIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 32 32"
    className="w-6 shrink-0"
  >
    <g>
      <path
        d="M29.464 14.114a1.003 1.003 0 0 0-1.033.063l-9.542 6.606-2.319-1.606a1.001 1.001 0 0 0-1.139 0l-2.32 1.606-8.267-5.723 3.671-2.203a1 1 0 1 0-1.029-1.715l-5 3A1.054 1.054 0 0 0 2 15v12c0 1.654 1.346 3 3 3h22c1.654 0 3-1.346 3-3V15c0-.372-.207-.713-.536-.886zM4.016 27.08C4.014 27.052 4 27.028 4 27V16.909L11.354 22zm2.186.92L16 21.216 25.799 28zM28 27c0 .028-.014.052-.016.08L20.646 22 28 16.909z"
        fill="#0375ff"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M21.383 13.924c.373-.155.617-.52.617-.924V4a1.002 1.002 0 0 0-1.707-.707C18.586 5 15.935 5 14 5h-1.5C10.57 5 9 6.57 9 8.5c0 1.818 1.399 3.299 3.174 3.467l.846 4.229a1 1 0 1 0 1.961-.393L14.22 12c1.913.002 4.428.061 6.073 1.706a.998.998 0 0 0 1.09.218zM13 10h-.5c-.827 0-1.5-.673-1.5-1.5S11.673 7 12.5 7h.5zm7 1.048c-1.645-.852-3.478-1.009-5-1.039V6.991c1.522-.03 3.355-.187 5-1.039zM26 7.5h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zM27.598 3.5a1 1 0 0 1-.366 1.366l-1.732 1a1 1 0 0 1-1-1.732l1.732-1a.999.999 0 0 1 1.366.366zM24.134 11.5a.999.999 0 0 1 1.366-.366l1.732 1a1 1 0 1 1-1 1.732l-1.732-1a1 1 0 0 1-.366-1.366z"
        fill="#0375ff"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
);
export default SMSCampaignIcon;
