import PopupIcon from "../../assets/icons/PopupIcon";
import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../components/RenderTableComponents/NormalText";
import OptInToolsIns from "../../components/RenderTableComponents/OptInTools/OptInToolsIns";
import OptInToolsRates from "../../components/RenderTableComponents/OptInTools/OptInToolsRates";
import Status from "../../components/RenderTableComponents/Status";

const columns = [
  {
    name: "Name",
    selector: (row) => row.popup_name,
    sortable: true,
    width: "30%",
    cell: (row) => (
      <CustomTitle
        title={row?.popup_name}
        description={
          <div className="flex capitalize">
            <PopupIcon />
            {row?.popup_type?.replaceAll("-", " ")}
            {row?.popup_device ? ` ( ${row?.popup_device} ) ` : ""}
          </div>
        }
      />
    ),
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => row.status && <Status row={row} />,
  },
  {
    name: "Impressions",
    selector: (row) => row.impressions,
    sortable: true,
    cell: (row) => <NormalText row={row.impressions} />,
  },
  {
    name: "Opt-in rates",
    selector: (row) => row.sms_optin_rate_per,
    sortable: true,
    cell: (row) => <OptInToolsRates row={row} />,
  },
  {
    name: "Opt-ins",
    selector: (row) => row.optins_sms,
    sortable: true,
    cell: (row) => <OptInToolsIns row={row} />,
  },
  {
    name: "New Subscriptions",
    selector: (row) => row.new_subscriptions,
    sortable: true,
    cell: (row) => (
      <NormalText
        row={
          <>
            <span className="text-gray"> SMS:</span> {row.new_subscriptions}
          </>
        }
      />
    ),
  },
];
export default columns;
