import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSubscribersChartDetails } from "../../../apis/Subscribers";
import LineChart from "../../../components/LineChart";
import queryParamConstant from "../../../utils/queryParamConstant";
import moment from "moment";

const Overview = () => {
  const [selectedRange, setSelectedRange] = useState("all");
  const [selectedDayRange, setSelectedDayRange] = useState("monthly");
  const [dropdownSelection, setDropdownSelection] = useState("new_subscribers");
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [dateRange, setDateRange] = useState();
  const [dateRangeValue, setDateRangeValue] = useState([
    startDateRange,
    endDateRange,
  ]);
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_SUBSCRIBERS_CHART_DATA_URL,
    () =>
      getSubscribersChartDetails({
        filter: {
          data_range: selectedRange,
          start_date:
            selectedRange === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            selectedRange === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        range_type: selectedDayRange,
        subscribers_chart_filter: dropdownSelection,
      }),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    if (startDateRange && endDateRange) {
      setTimeout(() => refetch(), 10);
    }
  }, [startDateRange, endDateRange]);

  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="relative">
      <LineChart
        isLoading={isLoading || isFetching}
        lineData={data}
        onFilterDayChange={(item) => {
          setSelectedDayRange(item);
          setTimeout(() => refetch(), 10);
        }}
        dateRange={dateRange}
        setStartDateRange={setStartDateRange}
        setEndDateRange={setEndDateRange}
        setDateRangeValue={setDateRangeValue}
        setDateRange={setDateRange}
        dateRangeValue={dateRangeValue}
        isDropdownSubscriptions={true}
        dropdownSelection={dropdownSelection}
        setDropdownSelection={(item) => {
          setDropdownSelection(item);
          setTimeout(() => refetch(), 10);
        }}
        filterSelectionDay={selectedDayRange}
        filterSelection={selectedRange}
        onFilterChange={(item) => {
          setSelectedRange(item);
          setTimeout(() => refetch(), 10);
        }}
      />
    </div>
  );
};
export default Overview;
