import { toast } from "react-toastify";
import {
  GET_OPTIN_DATA_URL,
  GET_SUBSCRIBERS_CHART_DATA_URL,
  GET_CAMPAIGN_FLOWS_LIST_URL,
  GET_AUTOMATED_FLOWS_LIST_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const getSubscribersChartDetails = (body) =>
  dataService
    .post(GET_SUBSCRIBERS_CHART_DATA_URL, body)
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const getSingleCampaignFlows = (body) =>
  dataService
    .post(GET_CAMPAIGN_FLOWS_LIST_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getAutomatedFlowDetailsList = (body) =>
  dataService
    .post(GET_AUTOMATED_FLOWS_LIST_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const getAllOptins = (body) =>
  dataService
    .post(GET_OPTIN_DATA_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
export {
  getAllOptins,
  getSingleCampaignFlows,
  getSubscribersChartDetails,
  getAutomatedFlowDetailsList,
};
