import * as React from "react";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import { useMutation } from "react-query";
import queryParamConstant from "../../../../utils/queryParamConstant";
import {
  updateCompanyDetails,
  updatePrivacyLinks,
} from "../../../../apis/Settings";

const CompanyInformation = ({ data }) => {
  const [companyInfoTerms, setCompanyInfoTerms] = React.useState(
    data?.company_info_terms
  );
  const [companyInfoPrivacy, setCompanyInfoPrivacy] = React.useState(
    data?.company_info_privacy
  );
  const [companyName, setCompanyName] = React.useState(data?.company_info_name);
  const [companyInfoPhone, setCompanyInfoPhone] = React.useState(
    data?.company_info_phone
  );
  const [companyInfoCountry, setCompanyInfoCountry] = React.useState(
    data?.company_info_country
  );
  const [companyInfoZip, setCompanyInfoZip] = React.useState(
    data?.company_info_zip
  );
  const [companyInfoCity, setCompanyInfoCity] = React.useState(
    data?.company_info_city
  );
  const [companyInfoState, setCompanyInfoState] = React.useState(
    data?.company_info_state
  );
  const [companyInfoAddress, setCompanyInfoAddress] = React.useState(
    data?.company_info_address
  );
  const { mutate: updateCompany } = useMutation(
    queryParamConstant.UPDATE_COMPANY_INFO_URL,
    () =>
      updateCompanyDetails({
        company_info_name: companyName,
        company_info_phone: companyInfoPhone,
        company_info_country: companyInfoCountry,
        company_info_zip: companyInfoCity,
        company_info_city: companyInfoCity,
        company_info_state: companyInfoState,
        company_info_address: companyInfoAddress,
      }),
    { refetchOnWindowFocus: false }
  );
  const { mutate: updateCompanyLink } = useMutation(
    queryParamConstant.UPDATE_PRIVACY_LINKS_URL,
    () =>
      updatePrivacyLinks({
        company_info_terms: companyInfoTerms,
        company_info_privacy: companyInfoPrivacy,
      }),
    { refetchOnWindowFocus: false }
  );

  return (
    <div className="flex flex-col">
      <div className="px-5 self-start text-[16px] text-blue leading-[20px] font-bold max-md:max-w-full">
        Company information
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex gap-5 justify-center mt-10 leading-[143%] max-md:flex-wrap">
        <CustomInput
          title="Company name"
          placeholder="enter"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <CustomInput
          title="Company phone number"
          placeholder="enter"
          value={companyInfoPhone}
          onChange={(e) => setCompanyInfoPhone(e.target.value)}
        />
      </div>
      <div className="flex gap-5 justify-center mt-8 leading-[143%] max-md:flex-wrap">
        <CustomInput
          title="Country"
          placeholder="enter"
          value={companyInfoCountry}
          onChange={(e) => setCompanyInfoCountry(e.target.value)}
        />
        <CustomInput
          title="ZIP/Postal code"
          placeholder="enter"
          value={companyInfoZip}
          onChange={(e) => setCompanyInfoZip(e.target.value)}
        />
      </div>
      <div className="flex gap-5 justify-center mt-8 whitespace-nowrap leading-[143%] max-md:flex-wrap">
        <CustomInput
          title="City"
          placeholder="enter"
          value={companyInfoCity}
          onChange={(e) => setCompanyInfoCity(e.target.value)}
        />
        <CustomInput
          title="State"
          placeholder="enter"
          value={companyInfoState}
          onChange={(e) => setCompanyInfoState(e.target.value)}
        />
      </div>
      <div className="flex flex-col items-start px-10 mt-8 w-full leading-[143%] max-md:px-5 max-md:max-w-full">
        <CustomInput
          title="Address line"
          subTitle="Street and number, building, floor etc."
          placeholder="enter"
          isPaddingZero={true}
          className="px-0"
          value={companyInfoAddress}
          onChange={(e) => setCompanyInfoAddress(e.target.value)}
        />
      </div>

      <div className="justify-center self-end mr-10 max-md:mr-2.5">
        <CustomButton
          title="Save"
          className="px-5 py-1.5 mt-5"
          onClick={() => updateCompany()}
        />
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="px-12 mt-6">
        <div className="self-self text-[14px] font-bold text-blue leading-[20px] max-md:max-w-full">
          Legal documents
        </div>
        <div className="self-center text-[14px] mt-1 mr-10 leading-[22px] text-zinc-600 font-normal max-md:max-w-full ">
          Add your Privacy Policy and Terms of Services link here so these can
          be added to your opt-in tools. If you haven't created them yet, please
          visit Shopify Legal pages or 
          <span className="text-teal-500">Learn more.</span>
        </div>
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex gap-5 justify-center mt-10 leading-[143%] max-md:flex-wrap">
        <CustomInput
          title="Terms of service (Optional)"
          value={companyInfoTerms}
          onChange={(e) => setCompanyInfoTerms(e.target.value)}
        />
        <CustomInput
          title="Privacy policy (Optional)"
          value={companyInfoPrivacy}
          onChange={(e) => setCompanyInfoPrivacy(e.target.value)}
        />
      </div>
      <div className="justify-center self-end mr-10 max-md:mr-2.5">
        <CustomButton
          title="Save"
          className="px-5 py-1.5 mt-5"
          onClick={() => updateCompanyLink()}
        />
      </div>
    </div>
  );
};
export default CompanyInformation;
