const CartAbdIcon = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6"
  >
    <path
      d="M19.828 10.243a2 2 0 112.829-2.829l8.485 8.485a2 2 0 01-2.828 2.829l-8.486-8.485zM18.142 10.243a2 2 0 10-2.828-2.829L6.829 15.9a2 2 0 102.828 2.829l8.485-8.485z"
      fill="#E0F2FF"
      stroke="#0375FF"
      strokeWidth="2"
    ></path>
    <path
      d="M34 15.586H4a2 2 0 100 4h30a2 2 0 100-4z"
      fill="#fff"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 19.586h30l-4.544 13.632a2 2 0 01-1.898 1.368H10.442a2 2 0 01-1.898-1.368L4 19.586z"
      fill="#fff"
      stroke="#0375FF"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
    <path
      d="M32 23.586a6 6 0 100-12 6 6 0 000 12z"
      fill="#E0F2FF"
      stroke="#0375FF"
      strokeWidth="2"
    ></path>
    <path
      clipRule="evenodd"
      d="M29.379 15.464c.244-.45.372-.5.5-.5s.256.05.353.147L32 16.878l1.769-1.767a.498.498 0 01.853.353.499.499 0 01-.146.354l-1.769 1.767 1.77 1.769a.498.498 0 01-.355.853.498.498 0 01-.353-.146l-1.769-1.769-1.767 1.769a.498.498 0 01-.853-.354c0-.128.049-.256.146-.354l1.767-1.768-1.767-1.767a.498.498 0 01-.146-.354z"
      stroke="#0375FF"
    ></path>
  </svg>
);
export default CartAbdIcon;
