import { toast } from "react-toastify";
import {
  GET_CHAT_MSGS_URL,
  GET_SUBSCRIBER_INFO_URL,
  GET_ALL_CHATS_URL,
  SENG_MSG_URL,
  GET_SUBSCRIBER_LIST_AND_SEGMENTS_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const getAllSubscribersChat = (body) =>
  dataService
    .post(GET_ALL_CHATS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const subscribersInfo = (body) =>
  dataService
    .post(GET_SUBSCRIBER_INFO_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getSubscriberMessages = (body) =>
  dataService
    .post(GET_CHAT_MSGS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const subscribersSegmentList = (body) =>
  dataService
    .post(GET_SUBSCRIBER_LIST_AND_SEGMENTS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });
const sendMessage = (body) =>
  dataService
    .post(SENG_MSG_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

export {
  getSubscriberMessages,
  subscribersSegmentList,
  subscribersInfo,
  getAllSubscribersChat,
  sendMessage,
};
