const tooltipData = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "optin",
    title: "Opt-in Tools",
  },
  {
    id: "automated",
    title: "Automated Flows",
  },
  {
    id: "campaign",
    title: "Campaign Flows",
  },
  {
    id: "subscribers",
    title: "Subscribers",
  },
  {
    id: "inbox",
    title: "Inbox",
  },
  {
    id: "add-manage",
    title: "Setup",
  },
  {
    id: "settings",
    title: "Settings",
  },
  {
    id: "manage",
    title: "Manage Role",
  },
  {
    id: "billing",
    title: "Billing",
  },
  {
    id: "reports",
    title: "Reports",
  },
  {
    id: "logout",
    title: "Logout",
  },
  {
    id: "segments",
    title: "Segments & Lists",
  },
  {
    id: "search-member",
    title:
      "Search for entire email address, first name, last name or phone number to get results.",
  },
  {
    id: "segment-Subscribers",
    title:
      "The current number of people who subscribed to any of the channels.",
  },
  {
    id: "site-url",
    title:
      "Site URL and currency is updated automatically based on your settings in Shopify.",
  },
  {
    id: "copy",
    title: "Copy to clipboard!",
  },
];
export default tooltipData;
