import { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import { useMutation } from "react-query";
import queryParamConstant from "../../../../utils/queryParamConstant";
import { UpdateContactInfoSetting } from "../../../../apis/Settings";

const ContactInformation = ({ data }) => {
  const [contactFirstName, setContactFirstName] = useState(
    data?.contact_info_fname
  );
  const [contactLastName, setContactLastName] = useState(
    data?.contact_info_lname
  );
  const [contactEmail, setContactEmail] = useState(data?.contact_info_email);
  const { mutate: updateContact } = useMutation(
    queryParamConstant.UPDATE_CONTACT_INFO_URL,
    () =>
      UpdateContactInfoSetting({
        contact_info_fname: contactFirstName,
        contact_info_lname: contactLastName,
        contact_info_email: contactEmail,
      }),
    { refetchOnWindowFocus: false }
  );
  return (
    <div className="flex flex-col">
      <div className="mx-5 text-base font-bold text-slate-800 max-md:mr-2.5 max-md:max-w-full">
        Contact information
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex gap-5 justify-center mt-5 leading-[143%] text-slate-800 max-md:flex-wrap">
        <CustomInput
          title="First name"
          placeholder="Enter"
          value={contactFirstName}
          onChange={(e) => setContactFirstName(e.target.value)}
        />
        <CustomInput
          title="Last name"
          placeholder="Enter"
          value={contactLastName}
          onChange={(e) => setContactLastName(e.target.value)}
        />
      </div>
      <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap max-md:px-5">
        <div className="flex flex-col justify-center w-[49%] max-md:max-w-full pl-10">
          <div className="font-bold leading-[143%] text-slate-800 max-md:max-w-full">
            Email address
          </div>
          <div className="mt-1 leading-6 text-zinc-600 max-md:max-w-full">
            We'll email you only if there is an issue with your Facebook Page
            connection, and your Simple SMS account is not working properly.
          </div>
        </div>
        <CustomInput
          title=""
          placeholder="Enter"
          className="w-full"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
      </div>

      <div className="justify-center self-end mr-10 max-md:mr-2.5">
        <CustomButton
          title="Save"
          className="px-5 py-1.5 mt-5"
          onClick={() => updateContact()}
        />
      </div>
    </div>
  );
};
export default ContactInformation;
