const HelpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 511.488 511.488"
    className="w-6 hovered-paths"
  >
    <g>
      <path
        d="M474.466 464.365c-5.75-26.34-35.749-128.779-150.967-165.362a128.444 128.444 0 0 0 32.066-28.684h44.816c25.978 0 47.111-21.134 47.111-47.11v-64.222c0-10.886-3.721-20.913-9.945-28.899-60.077-173.3-303.03-173.602-363.331.002-6.222 7.986-9.943 18.012-9.943 28.897v64.222c0 25.977 21.134 47.11 47.11 47.11H156.2a128.424 128.424 0 0 0 32.066 28.684C73.092 335.564 42.883 437.976 37.075 464.272c-5.052 22.23 11.692 47.216 38.232 47.216 78.106 0 285.586 0 361.151-.241 25.879-.001 42.925-23.939 38.008-46.882zm-56.973-305.378v64.222c0 9.435-7.676 17.11-17.111 17.11h-26.975c13.117-30.742 13.117-67.701 0-98.443h26.975c9.435-.001 17.111 7.675 17.111 17.111zm-279.134 81.332h-26.976c-9.435 0-17.11-7.676-17.11-17.11v-64.222c0-9.436 7.676-17.111 17.11-17.111h26.976c-13.117 30.742-13.117 67.7 0 98.443zM115.39 111.875c60.204-109.578 220.815-109.503 280.988 0h-40.813c-49.021-63.78-150.367-63.756-199.365 0zm43.104 79.222c0-53.7 43.688-97.388 97.388-97.388 66.214-1.29 115.139 69.016 91.57 130.555h-59.458c-19.872.734-19.874 29.266 0 30h41.95c-57.632 68.21-171.447 27.201-171.45-63.167zm284.825 286.892c-1.685 2.07-4.186 3.258-6.908 3.258-75.566.241-283.01.241-361.104.241-5.534.137-10.148-5.427-8.913-10.863 46.283-199.471 330.174-203.884 378.739.035a8.819 8.819 0 0 1-1.814 7.329z"
        fill="#0375ff"
        opacity="1"
        data-original="#000000"
        className="hovered-path"
      ></path>
    </g>
  </svg>
);
export default HelpIcon;
