import { useState } from "react";
import CustomInput from "../../../../components/CustomInput";

const AppCredentialInformation = ({ data }) => {
  const [clientId, setClientId] = useState(data?.client_id);
  const [clientSecret, setClientSecret] = useState(data?.client_secret);

  return (
    <div className="flex flex-col">
      <div className="mx-5 text-base font-bold text-slate-800 max-md:mr-2.5 max-md:max-w-full">
        App Credentials
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex gap-5 justify-center mt-5 leading-[143%] text-slate-800 max-md:flex-wrap">
        <CustomInput
          title="Client Id"
          disabled={true}
          placeholder="Enter"
          value={clientId}
          onChange={(e) => {}}
        />
        <CustomInput
          title="Client Secret Key"
          placeholder="Enter"
          disabled={true}
          value={clientSecret}
          onChange={(e) => {}}
        />
      </div>
    </div>
  );
};
export default AppCredentialInformation;
