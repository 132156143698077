import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getSubscribersHistory, subscribersInfo } from "../../apis/Subscribers";
import EditIcon from "../../assets/icons/EditIcon";
import SMSIcon from "../../assets/icons/SMSIcon";
import SubscriberSegments from "../../components/MessageDetails/SubscriberSegments";
import { formatTime } from "../../utils/formatDate";
import { convertUTCDateToLocalDate } from "../../utils/parseTimeZone";
import queryParamConstant from "../../utils/queryParamConstant";

const SubscribersDetailPage = () => {
  const { id } = useParams();
  const { data } = useQuery(
    queryParamConstant.GET_SUBSCRIBER_INFO_URL_DETAIL,
    () =>
      subscribersInfo({
        subscriber_id: id,
      }),
    { refetchOnWindowFocus: false, enabled: !!id }
  );
  const { data: history } = useQuery(
    queryParamConstant.GET_SUBSCRIPTION_HISTORY_URL,
    () =>
      getSubscribersHistory({
        subscriber_id: id,
      }),
    { refetchOnWindowFocus: false, enabled: !!id }
  );
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] p-8">
      <div className="flex gap-8 w-full">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="bg-white rounded-lg border border-1 border-[#DADADA]">
            <div className="py-2 px-3 flex border-b border-[#DADADA] border-1 items-center min-h-[56px]">
              <div className="w-full">
                <h3 className="text-[16px] text-blue font-medium">
                  Personal Info
                </h3>
              </div>
            </div>
            <div className="pt-4 px-4">
              <div className="data-grid">
                {data?.phone_no && (
                  <div className="flex justify-between">
                    <span className="text-[14px] text-blue font-medium flex">
                      Phone
                    </span>{" "}
                    <p className="text-[14px] text-zinc-600 text-medium">
                      {data?.phone_no}
                    </p>
                  </div>
                )}

                {data?.country_code && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      Country
                    </span>{" "}
                    <p className="text-[14px] text-zinc-600 text-medium">
                      {" "}
                      {data?.country_code}
                    </p>
                  </div>
                )}

                {data?.region_name && (
                  <div className="flex justify-between">
                    <span className="text-[14px] text-blue font-medium flex">
                      Region
                    </span>

                    <p className="text-[14px] text-zinc-600 text-medium">
                      {data?.region_name}
                    </p>
                  </div>
                )}
                {data?.city && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      City
                    </span>

                    <p className="text-[14px] text-zinc-600 text-medium">
                      {data?.city}
                    </p>
                  </div>
                )}
                {data?.language && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      Language
                    </span>

                    <p className="text-[14px] text-zinc-600 text-medium">
                      {" "}
                      {data?.language}
                    </p>
                  </div>
                )}

                {data?.timezone && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      Timezone
                    </span>{" "}
                    <p className="text-[14px] text-zinc-600 text-medium">
                      UTC-04.00
                    </p>
                  </div>
                )}

                {data?.first_name && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      First Name
                    </span>{" "}
                    <p className="text-[14px] text-zinc-600 text-medium">
                      {" "}
                      {data?.first_name}
                    </p>
                  </div>
                )}

                {data?.last_name && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      Last Name
                    </span>{" "}
                    <p className="text-[14px] text-zinc-600 text-medium">
                      {" "}
                      {data?.last_name}
                    </p>
                  </div>
                )}

                {data?.zip_code && (
                  <div className="flex justify-between my-5">
                    <span className="text-[14px] text-blue font-medium flex">
                      Zip code
                    </span>
                    <p className="text-[14px] text-zinc-600 text-medium">
                      {data?.zip_code}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            data-v-1e568c40=""
            className="bg-white rounded-lg border border-1 border-[#DADADA]"
          >
            <div
              data-v-1e568c40=""
              className="py-2 px-3 flex border-b border-1 border-[#DADADA] border-[#DADADA] items-center justify-between min-h-[56px]"
            >
              <h3
                data-v-1e568c40=""
                className="text-[16px] text-blue font-medium"
              >
                Lists
              </h3>
              <button
                data-v-1e568c40=""
                className="border-none outline-none text-blue text-[16px] text-medium cursor-pointer hover:outline-none hover:border-none !text-[14px]"
              >
                <EditIcon />
              </button>
            </div>
            <div data-v-1e568c40="" className="p-4">
              <p
                data-v-1e568c40=""
                className="text-[14px] text-zinc-600 text-medium"
              >
                Subscriber is not part of any lists
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-1 border-[#DADADA]">
            <div className="py-2 px-3 flex border-b border-[#DADADA] border-1 items-center min-h-[56px]">
              <div className="w-full">
                <h3 className="text-[16px] text-blue font-medium">Segments</h3>
              </div>
            </div>
            <div className="pb-4">
              <SubscriberSegments
                isLine={false}
                subscriberId={data?.subscriber_id}
              />
            </div>
          </div>
          <div
            data-v-9c6dbf49=""
            className="bg-white rounded-lg border border-1 border-[#DADADA]"
          >
            <div
              data-v-9c6dbf49=""
              className="py-2 px-3 flex border-b border-1 border-[#DADADA] items-center min-h-[56px]"
            >
              <div className="w-full">
                <h3 className="text-[16px] text-blue font-medium">
                  Shopify tags
                </h3>
              </div>
            </div>
            <div data-v-9c6dbf49="" className="p-4">
              <div data-v-9c6dbf49="">
                <p data-v-9c6dbf49="" className="text-sm text-blue text-medium">
                  Subscriber tags
                </p>
                <div data-v-9c6dbf49="" className="mt-2 flex flex-wrap gap-2">
                  {" "}
                  -{" "}
                </div>
              </div>
              <div data-v-9c6dbf49="" className="mt-3">
                <p data-v-9c6dbf49="" className="text-sm text-blue text-medium">
                  Order tags
                </p>
                <div data-v-9c6dbf49="" className="mt-2 flex flex-wrap gap-2">
                  {" "}
                  -{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-[2_2_0%] space-y-4">
          <div className="bg-white rounded-lg border border-1 border-[#DADADA]">
            <div className="py-2 px-3 flex border-b border-1 border-[#DADADA] items-center justify-between min-h-[56px]">
              <h3 className="text-[16px] text-blue font-medium">
                Subscriptions
              </h3>
              <button className="border-none outline-none text-blue text-[16px] text-medium cursor-pointer hover:outline-none hover:border-none ">
                <EditIcon />
              </button>
            </div>
            <div className="p-4">
              <div className="flex gap-3 items-center">
                <SMSIcon />
                <div>
                  <h3 className="text-[14px] text-blue font-medium">SMS</h3>
                  <p className="text-[14px] text-zinc-600 font-normal">
                    Subscribed
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-1 border-[#DADADA]">
            <div className="py-2 px-3 flex border-b border-1 border-[#DADADA] items-center min-h-[56px]">
              <div className="w-full">
                <h3 className="text-[16px] text-blue font-medium">
                  Subscription history
                </h3>
              </div>
            </div>
            <div className=" p-4">
              {history ? (
                <div className="flex gap-3 items-center">
                  <span>
                    <SMSIcon />
                  </span>
                  <div>
                    <p className="text-[14px] text-blue">
                      {history?.[0]?.history_details}{" "}
                      <span className="inline">
                        {" "}
                        on{" "}
                        <a
                          className="text-link-blue "
                          href={history?.[0]?.action_url}
                        >
                          {history?.[0]?.action_url}
                        </a>
                      </span>
                    </p>
                    <p className="text-[14px] text-zinc-600 font-normal">
                      {formatTime(
                        convertUTCDateToLocalDate(history?.[0]?.action_date)
                      ).toLocaleString()}{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <p class="text-base text-white-dark font-normal">
                  No subscription history found!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscribersDetailPage;
