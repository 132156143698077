import { toast } from "react-toastify";
import {
  IMPORT_SEGMENT_SUBSCRIBERS_URL,
  GET_SEGMENTS_LIST_URL,
  GET_SEGMENT_DETAILS_URL,
  GET_SEGMENT_CAMPAIGNS_URL,
  GET_SEGMENT_SUBSCRIBERS_URL,
} from "../../config/Api";
import { dataService } from "../../config/DataService";

const getSegmentsLists = (body) =>
  dataService
    .post(GET_SEGMENTS_LIST_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getSegmentsListsDetail = (body) =>
  dataService
    .post(GET_SEGMENT_DETAILS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getCampaignSegmentsListsDetail = (body) =>
  dataService
    .post(GET_SEGMENT_CAMPAIGNS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getSegmentsSubscribersDetail = (body) =>
  dataService
    .post(GET_SEGMENT_SUBSCRIBERS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

const getImportSegmentDetail = (body) =>
  dataService
    .post(IMPORT_SEGMENT_SUBSCRIBERS_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

export {
  getSegmentsLists,
  getSegmentsListsDetail,
  getCampaignSegmentsListsDetail,
  getSegmentsSubscribersDetail,
  getImportSegmentDetail,
};
