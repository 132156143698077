/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import getOptInToolsList from "../../apis/OptInTools";
import CustomLoader from "../../components/CustomLoader";
import CustomSortFilter from "../../components/CustomSortFilter";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";

const OptInTools = () => {
  const [selectedRange, setSelectedRange] = useState("all");
  const [page, setPage] = useState(1);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [sortDeviceFilterData, setSortDeviceFilterData] = useState([]);
  const [sortStatusFilterData, setSortStatusFilterData] = useState([]);
  const [sortPopupFilterData, setSortPopupFilterData] = useState([]);
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [isFetchingSearch, setFetchingSearch] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const { error, isLoading, refetch, isFetching } = useQuery(
    queryParamConstant.GET_OPTIN_DATA_URL,
    () =>
      getOptInToolsList({
        filter: {
          search_text: searchData,
          popup_type: sortPopupFilterData.length < 0 ? "" : sortPopupFilterData,
          status: sortStatusFilterData.length < 0 ? "" : sortStatusFilterData,
          device: sortDeviceFilterData.length < 0 ? "" : sortDeviceFilterData,
          data_range: selectedRange,
          start_date:
            selectedRange === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            selectedRange === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setTotal(list?.total);
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );
  const refetchAll = async () => {
    setFetchingSearch(true);
    const response = await getOptInToolsList({
      filter: {
        search_text: searchData,
        popup_type: sortPopupFilterData.length < 0 ? "" : sortPopupFilterData,
        status: sortStatusFilterData.length < 0 ? "" : sortStatusFilterData,
        device: sortDeviceFilterData.length < 0 ? "" : sortDeviceFilterData,
        data_range: selectedRange,
        start_date:
          selectedRange === "custom"
            ? moment(startDateRange).format("YYYY-MM-DD")
            : "",
        end_date:
          selectedRange === "custom"
            ? moment(endDateRange).format("YYYY-MM-DD")
            : "",
      },
      page: page,
      limit: perPage,
      is_with_paginate: 1,
    });
    setTotal(response?.total);
    setFilterData(response?.list);
    setAllDataList([...allDataList, ...response?.list]);
    setFetchingSearch(false);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetchAll();
      // Send Axios request here
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);
  useEffect(() => {
    if (startDateRange && endDateRange) {
      setTimeout(() => refetch(), 10);
    }
  }, [startDateRange, endDateRange]);

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isLoading={isFetching || isFetchingSearch}
          refetch={refetch}
          totalRows={total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={columns}
          filterSelection={selectedRange}
          startDateRange={startDateRange}
          searchData={searchData}
          endDateRange={endDateRange}
          setStartDateRange={setStartDateRange}
          setEndDateRange={setEndDateRange}
          setAllDataList={setAllDataList}
          onSearchFilter={(value) => {
            setAllDataList([]);
            setSearchData(value);
            setPage(1);
          }}
          leftSideFilter={
            <CustomSortFilter
              totalData={total}
              selected={"all"}
              sortStatusFilterData={sortStatusFilterData}
              sortDeviceFilterData={sortDeviceFilterData}
              sortPopupFilterData={sortPopupFilterData}
              setSortPopupFilterData={setSortPopupFilterData}
              setSortStatusFilterData={setSortStatusFilterData}
              setSortDeviceFilterData={setSortDeviceFilterData}
              onClick={() => setTimeout(() => refetch(), 10)}
              onClearClick={() => {
                setAllDataList([]);
                setSortPopupFilterData([]);
                setSortStatusFilterData([]);
                setSortDeviceFilterData([]);
                setTimeout(() => refetch(), 10);
              }}
            />
          }
          onFilterChange={(item) => {
            setAllDataList([]);
            if (item !== "custom") {
              setPage(1);
              setSelectedRange(item);
              setTimeout(() => refetch(), 10);
            } else {
              setPage(1);
              setSelectedRange(item);
            }
          }}
        />
      )}
    </div>
  );
};
export default OptInTools;
