import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { useMutation } from "react-query";
import { getRevenueReport } from "../../apis/Report";
import { isInvalid } from "../../utils/formValidator";
import { toast } from "react-toastify";
import queryParamConstant from "../../utils/queryParamConstant";

const ReportsPage = () => {
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { mutate: submitData } = useMutation(
    queryParamConstant.GET_REVENUE_REPORT_URL,
    ({ email, start_date, end_date }) =>
      getRevenueReport({
        email,
        start_date,
        end_date,
      }),
    {
      onSuccess: (data) => {
        toast.error(data?.message || "Successfully reported!", {
          toastId: "1",
        });
      },
    }
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      isInvalid(email, "email") ||
      isInvalid(startDate, "date") ||
      isInvalid(endDate, "date")
    ) {
      toast.error("Invalid input", {
        toastId: "1",
      });
      return;
    }

    submitData({
      email,
      start_date: startDate,
      end_date: endDate,
    });
  };
  return (
    <div className="relative">
      <div className="flex flex-col pt-10 pb-20 h-[80vh] text-sm leading-5 bg-white rounded-xl border-2 border-solid border-gray-light">
        <div className="self-start ml-5 text-lg font-semibold leading-7 text-blue max-md:ml-2.5">
          Revenue Report
        </div>
        <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
        <form className="flex flex-col" onSubmit={onSubmit}>
          <div className="flex gap-5 justify-center mt-10 max-md:flex-wrap">
            <CustomInput
              type="email"
              title="Email (to send CSV file)"
              placeholder="Enter"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <CustomInput title="User type" placeholder="Enter" /> */}
          </div>
          <div className="flex gap-5 justify-between mt-8 max-md:flex-wrap">
            <CustomInput
              title="From"
              placeholder="mm/dd/yyyy"
              type="date"
              required
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <CustomInput
              title="To"
              placeholder="mm/dd/yyyy"
              type="date"
              required
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <CustomButton
            type="submit"
            title="Export"
            className={"mr-10 mt-8 px-5 py-1.5 w-[103px]"}
          />
        </form>
      </div>
    </div>
  );
};
export default ReportsPage;
