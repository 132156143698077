const PopupIcon = () => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shrink-0 w-3 stroke-gray-500 mr-1"
  >
    <rect x="4" y="5" width="8" height="6" rx="1" strokeWidth="2"></rect>
    <rect x="1" y="1" width="14" height="14" rx="1" strokeWidth="2"></rect>
  </svg>
);
export default PopupIcon;
