/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { subscribersSegmentList } from "../../apis/Inbox";
import RightArrow from "../../assets/icons/RightArrow";
import queryParamConstant from "../../utils/queryParamConstant";

const SubscriberSegments = ({ subscriberId, isLine = true }) => {
  const navigate = useNavigate();
  const [isMore, setMore] = useState(false);
  const { data, refetch } = useQuery(
    queryParamConstant.GET_SUBSCRIBER_LIST_AND_SEGMENTS_URL,
    () =>
      subscribersSegmentList({
        subscriber_id: subscriberId,
        type: "segment",
      }),
    { refetchOnWindowFocus: false, enabled: !!subscriberId }
  );
  useEffect(() => {
    if (subscriberId) {
      setTimeout(() => refetch(), 10);
      setMore(false);
    }
  }, [subscriberId]);
  return (
    <div>
      {data?.length > 0 ? (
        <>
          {isLine && (
            <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full items-center" />
          )}
          {!isMore
            ? data.slice(0, 6)?.map((item) => (
                <div
                  onClick={() => {
                    navigate("/segments/" + item.id, {
                      state: {
                        id: item.id,
                      },
                    });
                  }}
                  className="flex mx-5 mt-5 max-md:mx-2.5 cursor-pointer"
                >
                  <div className="flex-1 text-[14px] text-blue font-medium leading-[20px]">
                    {item?.name}
                  </div>
                  <RightArrow color="#111827" />
                </div>
              ))
            : data?.map((item) => (
                <div
                  onClick={() => {
                    navigate("/segments/" + item.id, {
                      state: {
                        id: item.id,
                      },
                    });
                  }}
                  className="flex mx-5 mt-5 max-md:mx-2.5 cursor-pointer"
                >
                  <div className="flex-1 text-[14px] text-blue font-medium leading-[20px]">
                    {item?.name}
                  </div>
                  <RightArrow color="#111827" />
                </div>
              ))}
          {data.length > 6 && (
            <div
              className="mx-5 my-5 text-blue-800 leading-[22px] max-md:mx-2.5 items-center cursor-pointer"
              onClick={() => setMore(!isMore)}
            >
              See {!isMore ? "More" : "Less"}...
            </div>
          )}
        </>
      ) : (
        <div className="mx-5 mt-6 flex-1 text-[14px] text-zinc-600 font-medium">
          Subscriber is not part of any segments
        </div>
      )}
    </div>
  );
};
export default SubscriberSegments;
