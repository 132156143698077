import Status from "../../../../components/RenderTableComponents/Status";
import USFlag from "../../../../assets/images/us-flag.png";
import CustomTitle from "../../../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../../../components/RenderTableComponents/NormalText";
import { formatDate } from "../../../../utils/formatDate";
import { sendingNumberType } from "../../../../global/config";

const columns = [
  {
    name: "",
    selector: (row) => row.members,
    sortable: false,
    width: "8%",
    cell: (row) => (
      <CustomTitle
        title={<img src={USFlag} alt="us flag" className="w-[36px]" />}
      />
    ),
  },
  {
    name: "Phone Number",
    selector: (row) => row.name,
    sortable: true,
    width: "40%",
    cell: (row) => (
      <CustomTitle title={row?.phone_no} description={"United States"} />
    ),
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => <Status row={row} />,
  },
  {
    name: "Date added",
    selector: (row) => row.updated_at,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row?.updated_at)} />,
  },
  {
    name: "Type",
    selector: (row) => row.type,
    sortable: true,
    cell: (row) => <NormalText row={sendingNumberType[row.type]} />,
  },
];
export default columns;
