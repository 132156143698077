import { toast } from "react-toastify";
import { GET_OPTIN_DATA_URL } from "../../config/Api";
import { dataService } from "../../config/DataService";

const getOptInToolsList = (body) =>
  dataService
    .post(GET_OPTIN_DATA_URL, body)
    .then(({ data }) => {
      return data?.data;
    })
    .catch((e) => {
      toast.error("invalid credentials", {
        toastId: "1",
      });
    });

export default getOptInToolsList;
