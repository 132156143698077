import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getSegmentsListsDetail } from "../../apis/SegmentsLists";
import EditIcon from "../../assets/icons/EditIcon";
import MembersIcon from "../../assets/icons/MembersIcon";
import SegmentsIcon from "../../assets/icons/SegmentIcon";
import CustomLoader from "../../components/CustomLoader";
import { formatDate } from "../../utils/formatDate";
import queryParamConstant from "../../utils/queryParamConstant";

const Details = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useQuery(
    queryParamConstant.GET_SEGMENT_DETAILS_INSIDE_URL,
    () =>
      getSegmentsListsDetail({
        id: id,
      }),
    { refetchOnWindowFocus: false, enabled: !!id }
  );

  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="mt-5 mx-10">
      <div className="self-start text-base font-bold max-md:max-w-full mb-5">
        {data?.name}
      </div>
      <div className="flex flex-col self- text-sm py-5 font-medium bg-white rounded-xl border border-[#DADADA] border-solid text-slate-800 max-md:max-w-full">
        <div className="self-start text-base font-bold max-md:max-w-full px-5">
          Personal info
        </div>
        {isLoading ? (
          <div className="h-[110px]">
            <CustomLoader />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex gap-3 px-5  mt-4 pt-5 leading-[143%] max-md:flex-wrap border-t-[1px] border-[#DADADA]">
              <EditIcon />
              <div className="flex flex-1 gap-1 self-start max-md:flex-wrap">
                <div className="flex-1 max-md:max-w-full font-medium text-[14px] leading-[20px]">
                  {data?.details}
                </div>
                <div className="flex-1 max-md:max-w-full flex font-medium text-[14px] leading-[20px]">
                  Last edited on
                  <div className="flex-1 font-medium text-[14px] leading-[20px] text-zinc-600 max-md:max-w-full ml-3">
                    {formatDate(data?.updated_at)}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-3 px-5 mt-5 leading-[143%] max-md:flex-wrap">
              <MembersIcon />
              <div className="flex flex-1 gap-1 self-start max-md:flex-wrap">
                <div className="flex-1 max-md:max-w-full font-medium text-[14px] leading-[20px]">
                  {data?.members} members
                </div>
                <div className="flex-1 max-md:max-w-full flex font-medium text-[14px] leading-[20px]">
                  Created on
                  <div className="flex-1 font-medium text-[14px] leading-[20px] text-zinc-600 max-md:max-w-full ml-3">
                    {formatDate(data?.created_at)}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-3 px-5 mt-5 whitespace-nowrap leading-[143%] max-md:flex-wrap">
              <SegmentsIcon />
              <div className="flex-1 my-auto max-md:max-w-full capitalize font-medium text-[14px] leading-[20px]">
                {data?.type}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Details;
