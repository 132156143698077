import LayoutIcon from "../../assets/icons/LayoutIcon";
import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../components/RenderTableComponents/NormalText";
import Status from "../../components/RenderTableComponents/Status";
import { formatDate } from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";

const membersColumns = [
  {
    name: "Image",
    sortable: false,
    width: "6.5%",
    cell: (row) => (
      <div className="flex justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M30.01 15.0098C30.01 19.0357 28.4239 22.6912 25.8425 25.3854C23.1123 28.2354 19.2683 30.0098 15.01 30.0098C10.7517 30.0098 6.90776 28.2354 4.17751 25.3854C1.59613 22.6911 0.0100098 19.0357 0.0100098 15.0098C0.0100098 6.72559 6.72583 0.00976562 15.01 0.00976562C23.2942 0.00976562 30.01 6.72559 30.01 15.0098Z"
            fill="#E7E7E7"
          />
          <path
            d="M15.01 15.8144C18.3943 15.8144 21.1379 13.0708 21.1379 9.68648C21.1379 6.30214 18.3943 3.55859 15.01 3.55859C11.6257 3.55859 8.88211 6.30214 8.88211 9.68648C8.88211 13.0708 11.6257 15.8144 15.01 15.8144Z"
            fill="#B0B6B7"
          />
          <path
            d="M25.3342 24.146C25.2141 24.2818 25.0912 24.4153 24.9656 24.5463C22.4563 27.1656 18.9236 28.7964 15.0099 28.7964C11.0963 28.7964 7.56355 27.1656 5.05427 24.5463C4.92874 24.4154 4.80584 24.2818 4.6857 24.146C6.53241 20.3031 10.4615 17.6504 15.0099 17.6504C19.5583 17.6504 23.4875 20.3032 25.3342 24.146Z"
            fill="#B0B6B7"
          />
        </svg>
      </div>
    ),
  },
  {
    name: "Name",
    selector: (row) => row.phone_no,
    sortable: true,
    width: "68%",
    cell: (row) => (
      <CustomTitle
        title={row?.phone_no}
        row={row.subscriber_segment_id}
        link={"/subscribers/" + row.subscriber_segment_id}
      />
    ),
  },
  {
    name: "	Subscribed At",
    selector: (row) => row.created_at,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row.created_at)} />,
  },
  {
    name: "Last Updated",
    selector: (row) => row.updated_at,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row.updated_at)} />,
  },
];
const campaignColumns = [
  {
    name: "Image",
    sortable: false,
    width: "5.2%",
    cell: (row) => (
      <div className="flex justify-center items-center ml-2">
        <LayoutIcon />
      </div>
    ),
  },
  {
    name: "Name",
    selector: (row) => row.flow_name,
    sortable: true,
    width: "35%",
    cell: (row) => (
      <CustomTitle title={row?.flow_name} description={row?.flow_description} />
    ),
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    width: "13%",
    cell: (row) => row.status && <Status row={row} />,
  },
  {
    name: "Recipients",
    selector: (row) => row.recipients,
    sortable: true,
    cell: (row) => <NormalText row={formatNumber(row.recipients)} />,
  },

  {
    name: "Spent",
    selector: (row) => row.spent,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.spent)}`} />,
  },
  {
    name: "Sales",
    selector: (row) => row.sales,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.sales)}`} />,
  },
  {
    name: "RPM",
    selector: (row) => row.rpm,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.rpm)}`} />,
  },
  {
    name: "ROI",
    selector: (row) => row.roi,
    sortable: true,
    cell: (row) => <NormalText row={`${formatNumber(row.roi)}X`} />,
  },
];
export { campaignColumns, membersColumns };
