const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M3.2001 10.9004L1.00002 1.00002L20.8008 10.9004L1.00002 20.8008L3.2001 10.9004ZM3.2001 10.9004H12.0004"
        stroke="white"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SendIcon;
