import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getAllOptins } from "../../apis/Home";
import queryParamConstant from "../../utils/queryParamConstant";
import CustomLoader from "../CustomLoader";
import RenderTable from "../RenderTable";
import columns from "./DatatableConfig";

const TopPerformingTools = () => {
  const { data, error, isLoading, isFetching } = useQuery(
    queryParamConstant.GET_OPTIN_DATA_URL_HOME,
    () =>
      getAllOptins({
        filter: {
          search_text: "",
          popup_type: "",
          status: "",
          device: "",
          data_range: "all",
          start_date: "",
          end_date: "",
        },
        page: 1,
        limit: 3,
        is_with_paginate: 1,
      }),
    { refetchOnWindowFocus: false }
  );
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="flex flex-col w-full">
      {" "}
      <div className="justify-center px-5 py-[1.9rem] text-base font-bold leading-7 text-white bg-blue-800 rounded-xl border border-solid border-gray-light">
        Top performing opt-in tools
      </div>
      <div className="flex flex-col relative -mt-2 w-full text-sm bg-white rounded-none rounded-b-xl border-r border-b border-l border-solid border-gray-light">
        {" "}
        {isLoading || isFetching ? (
          <div className="h-[280px]">
            <CustomLoader />
          </div>
        ) : (
          <RenderTable
            isFilterLayout={false}
            columns={columns}
            tableData={data?.list}
          />
        )}
        <Link
          className="flex flex-col justify-end items-end px-5 pt-px pb-4 w-full font-medium text-blue-800 leading-[157%]"
          to="/optin-tools"
        >
          <div className="justify-center px-5 py-1.5 bg-white rounded border border-solid border-gray-light">
            See All
          </div>
        </Link>
      </div>
    </div>
  );
};
export default TopPerformingTools;
