const HeaderData = {
  "optin-tools": {
    title: "Opt-in Tools",
    subtitle: "Create a new opt-in tool and start growing your audience.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  "automated-flows": {
    title: "Automated Flows",
    subtitle: "Engage your subscribers with automated conversations.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  "campaign-flows": {
    title: "Campaign Flows",
    subtitle:
      "Send message flows to your subscribers to promote flash sales, events or product launches.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  sequences: {
    title: "Campaign Flows",
    subtitle:
      "Send message flows to your subscribers to promote flash sales, events or product launches.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  inbox: {
    title: "Inbox",
    subtitle: "Answer any incoming text messages from your subscribers.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  subscribers: {
    title: "Subscribers",
    subtitle:
      "Check your subscription growth over time and see details of your subscriber's profiles.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  segments: {
    title: "Segments & Lists",
    subtitle:
      "Create smaller groups of subscribers with segmentation or add custom lists and target them with specific flows.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  settings: {
    title: "Settings",
    subtitle: "Manage your general and SMS-related settings here.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  roles: {
    title: "Manage Roles",
    subtitle: "Add new roles and edit previously created roles",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  "roles/add": {
    title: "Add Roles",
    subtitle: "Add new roles and edit previously created roles",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
      {
        id: 2,
        title: "Manage Roles",
        path: "/roles",
      },
      {
        id: 3,
        title: "Add Roles",
        path: "/roles/add",
      },
    ],
  },
  billing: {
    title: "Billing",
    subtitle:
      "Review your billing status, active subscription plan and recent invoices.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
  reports: {
    title: "Reports",
    subtitle:
      "Review your billing status, active subscription plan and recent invoices.",
    breadcrumbs: [
      {
        id: 1,
        title: "Home",
        path: "/",
      },
    ],
  },
};
export default HeaderData;
