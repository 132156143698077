/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getSubscriberMessages, sendMessage } from "../../apis/Inbox";
import SendIcon from "../../assets/icons/SendIcon";
import queryParamConstant from "../../utils/queryParamConstant";
import CustomButton from "../CustomButton";
import CustomLoader from "../CustomLoader";
import Items from "./Items";

const MessageView = ({ subscriberId }) => {
  const messagesEndRef = useRef();
  const [message, setMessage] = useState("");
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_CHAT_MSGS_URL,
    () =>
      getSubscriberMessages({
        subscriber_id: subscriberId,
      }),
    { refetchOnWindowFocus: false, enabled: !!subscriberId }
  );
  const { mutate: sendMessageApi } = useMutation(
    queryParamConstant.GET_CHAT_MSGS_URL,
    () =>
      sendMessage({
        subscriber_id: subscriberId,
        message,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!subscriberId,
      onSuccess: () => {
        setTimeout(() => refetch(), 10);
      },
    }
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (subscriberId) {
      setTimeout(() => refetch(), 10);
    }
  }, [subscriberId]);

  useEffect(() => {
    setTimeout(() => scrollToBottom(), 500);
  }, [data, subscriberId, isFetching]);

  const sendMessageHandler = () => {
    if (message.trim().length) {
      sendMessageApi();
    }
  };
  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="flex flex-col w-[38%] bg-light-sky rounded-xl max-md:mt-10 relative">
      <div className="message-view overflow-auto h-[82vh] px-5">
        {isLoading || isFetching ? (
          <div>
            <CustomLoader />
          </div>
        ) : (
          <div className="flex flex-col h-full">
            {data?.map((item, index) => (
              <Items key={index} item={item} />
            ))}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center px-6 py-5 text-base bg-white shadow-sm text-slate-50 max-md:px-5 absolute bottom-0 right-0 left-0">
        <div className="flex gap-4 max-md:flex-wrap">
          <div className="flex flex-col flex-1 justify-center bg-blue-800 rounded-md backdrop-blur-[2px]">
            <div className="flex gap-4 px-4 py-3 rounded">
              {/* <SmileIcon /> */}
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write a message..."
                className="bg-blue-800 w-full text-white border-none outline-none placeholder-white"
              />
            </div>
          </div>
          <CustomButton
            onClick={() => sendMessageHandler()}
            title={<SendIcon />}
            className={"pl-5 pr-4"}
          ></CustomButton>
        </div>
      </div>
    </div>
  );
};
export default MessageView;
