const NotCampaign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="338"
    height="270"
    viewBox="0 0 338 270"
    fill="none"
  >
    <g opacity="0.2">
      <path
        d="M87.0583 77.0811C83.3255 75.3261 77.1493 78.1813 75.185 82.3596C71.4253 90.3313 83.5753 101.881 87.9965 100.639C92.9375 99.2548 93.9095 80.3076 87.0583 77.0811Z"
        stroke="#0F31AC"
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="3.38 3.38"
      />
      <path
        d="M68.388 95.1439C67.0515 94.3744 52.944 86.2069 44.952 92.6666C39.3495 97.2026 39.2955 106.902 43.5075 112.35C51.8573 123.143 77.7368 118.303 80.4233 110.581C82.2255 105.384 73.7408 98.2421 68.388 95.1439Z"
        stroke="#0F31AC"
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="3.38 3.38"
      />
      <path
        d="M86.5454 228.422C120.896 219.897 159.925 211.217 220.533 212.695C254.425 213.518 262.106 203.002 287.797 189.927C310.693 178.27 321.938 155.09 315.033 141.955C308.06 128.691 286.818 133.97 269.43 114.233C261.505 105.242 262.903 98.9372 258.326 88.9674C243.847 57.3977 189.564 32.7197 162.8 45.7809C127.437 63.0339 157.67 137.581 118.142 157.939C93.1401 170.825 74.2401 146.093 49.5554 164.972C37.3244 174.328 22.3596 187.443 21.2054 201.692C21.2054 201.692 19.5854 236.522 86.5454 228.422Z"
        stroke="#0F31AC"
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="3.38 3.38"
      />
    </g>
    <path
      d="M176.415 112.505L117.487 160.119L238.292 160.099L179.351 112.505C178.494 111.816 177.272 111.816 176.415 112.505Z"
      fill="#27589B"
    />
    <path
      d="M238.292 160.098L177.9 208.893L117.487 160.118L238.292 160.098Z"
      fill="#27589B"
    />
    <path
      d="M232.089 238.224L123.724 238.244L123.704 129.88L232.069 129.859L232.089 238.224Z"
      fill="white"
    />
    <path
      d="M139.546 149.618L141.942 149.591L144.338 149.564L149.131 149.523L158.716 149.469C161.915 149.449 165.108 149.442 168.301 149.435L177.892 149.422L187.477 149.429L192.27 149.442L194.666 149.449L197.062 149.462L206.647 149.51L211.44 149.55L213.836 149.577L216.232 149.604L213.836 149.631L211.44 149.651L206.647 149.699L197.062 149.753L194.666 149.766L192.27 149.773L187.477 149.786L177.892 149.8L168.301 149.786C165.108 149.78 161.915 149.78 158.716 149.759L149.131 149.705L144.338 149.665L141.942 149.645L139.546 149.618Z"
      fill="#829CF8"
    />
    <path
      d="M139.546 159.461L141.942 159.428L144.338 159.407L149.131 159.367L158.716 159.313C161.915 159.293 165.108 159.286 168.307 159.279L177.892 159.266L187.477 159.272L192.27 159.286L194.666 159.293L197.062 159.306L206.647 159.353L211.44 159.394L213.843 159.414L216.239 159.448L213.843 159.475L211.44 159.495L206.647 159.542L197.062 159.596L194.666 159.61L192.27 159.617L187.477 159.63L177.892 159.644L168.307 159.63C165.108 159.623 161.915 159.623 158.716 159.603L149.131 159.549L144.338 159.509L141.942 159.488L139.546 159.461Z"
      fill="#829CF8"
    />
    <path
      d="M139.546 169.299L141.942 169.266L144.338 169.245L149.131 169.205L158.722 169.151C161.915 169.124 165.108 169.124 168.307 169.11L177.892 169.104L187.477 169.11L192.27 169.124L194.666 169.131L197.062 169.144L206.654 169.191L211.447 169.232L213.843 169.252L216.239 169.279L213.843 169.313L211.447 169.333L206.654 169.374L197.062 169.428L194.666 169.441L192.27 169.455L187.477 169.468L177.892 169.475L168.307 169.468C165.108 169.461 161.915 169.461 158.722 169.434L149.131 169.387L144.338 169.347L141.942 169.326L139.546 169.299Z"
      fill="#829CF8"
    />
    <path
      d="M139.553 179.141L141.949 179.107L144.345 179.087L149.138 179.047L158.723 178.993C161.916 178.966 165.115 178.966 168.308 178.952L177.893 178.945L187.485 178.952L192.277 178.966L194.673 178.972L197.07 178.979L206.655 179.033L211.447 179.074L213.843 179.094L216.24 179.121L213.843 179.155L211.447 179.175L206.655 179.215L197.07 179.269L194.673 179.283L192.277 179.296L187.485 179.31L177.893 179.317L168.308 179.31C165.115 179.303 161.916 179.296 158.723 179.276L149.138 179.229L144.345 179.188L141.949 179.168L139.553 179.141Z"
      fill="#829CF8"
    />
    <path
      d="M139.553 188.971L141.949 188.944L144.345 188.924L149.138 188.883L158.723 188.823C161.922 188.802 165.115 188.802 168.308 188.789L177.9 188.775L187.485 188.789L192.277 188.796L194.673 188.802L197.07 188.816L206.655 188.87L211.447 188.91L213.843 188.931L216.24 188.958L213.843 188.991L211.447 189.012L206.655 189.052L197.07 189.106L194.673 189.12L192.277 189.126L187.485 189.147L177.9 189.153L168.308 189.147C165.115 189.133 161.922 189.133 158.723 189.113L149.138 189.066L144.345 189.025L141.949 189.005L139.553 188.971Z"
      fill="#829CF8"
    />
    <path
      d="M139.553 198.815L141.949 198.788L144.345 198.768L149.138 198.72L158.73 198.666C161.922 198.646 165.115 198.646 168.315 198.633L177.9 198.619L187.485 198.633L192.277 198.639L194.673 198.646L197.07 198.66L206.655 198.714L211.454 198.754L213.85 198.774L216.246 198.801L213.85 198.828L211.454 198.855L206.655 198.896L197.07 198.95L194.673 198.963L192.277 198.97L187.485 198.984L177.9 198.997L168.315 198.99C165.115 198.977 161.922 198.977 158.73 198.957L149.138 198.909L144.345 198.869L141.949 198.842L139.553 198.815Z"
      fill="#829CF8"
    />
    <path
      d="M139.553 208.659L141.949 208.632L144.345 208.611L149.145 208.564L158.73 208.51C161.922 208.49 165.122 208.49 168.315 208.476L177.9 208.463L187.485 208.47L192.277 208.483L194.68 208.49L197.076 208.503L206.661 208.557L211.454 208.598L213.85 208.618L216.246 208.645L213.85 208.672L211.454 208.699L206.661 208.74L197.076 208.794L194.68 208.807L192.277 208.814L187.485 208.827L177.9 208.841L168.315 208.834C165.122 208.821 161.922 208.821 158.73 208.8L149.145 208.753L144.345 208.706L141.949 208.686L139.553 208.659Z"
      fill="#829CF8"
    />
    <path
      d="M139.559 218.5L141.956 218.473L144.352 218.453L149.144 218.406L158.729 218.352C161.922 218.332 165.122 218.332 168.314 218.318L177.899 218.305L187.491 218.311L192.284 218.325L194.68 218.332L197.076 218.345L206.661 218.399L211.454 218.44L213.85 218.46L216.246 218.487L213.85 218.514L211.454 218.534L206.661 218.581L197.076 218.635L194.68 218.649L192.284 218.656L187.491 218.669L177.899 218.683L168.314 218.676C165.122 218.662 161.922 218.662 158.729 218.642L149.144 218.588L144.352 218.548L141.956 218.527L139.559 218.5Z"
      fill="#829CF8"
    />
    <path
      opacity="0.1"
      d="M238.292 160.098L177.9 208.893L117.487 160.118H118.426L177.9 208.144L237.361 160.098H238.292Z"
      fill="black"
    />
    <path
      d="M238.292 160.098L238.312 245.08L117.508 245.107L117.487 160.118L177.9 208.893L238.292 160.098Z"
      fill="#2B61AB"
    />
    <path
      d="M238.313 245.078C233.831 241.663 229.389 238.187 224.975 234.683L223.314 233.374L221.661 232.057L218.353 229.425L211.765 224.133L205.204 218.807L201.93 216.127L200.297 214.791L198.663 213.448C194.316 209.864 189.983 206.259 185.697 202.594C190.179 206.016 194.613 209.492 199.035 212.989L200.695 214.298L202.342 215.615L205.65 218.247L212.238 223.546L218.805 228.872L222.072 231.545L223.713 232.888L225.339 234.231C229.693 237.815 234.027 241.42 238.313 245.078Z"
      fill="#E3E9F7"
    />
    <path
      d="M170.104 202.602C165.818 206.267 161.491 209.871 157.137 213.456L155.51 214.799L153.877 216.142L150.603 218.815L144.042 224.148L137.454 229.44L134.153 232.079L132.5 233.395L130.839 234.705C126.425 238.208 121.99 241.684 117.508 245.106C121.787 241.441 126.121 237.837 130.468 234.246L132.101 232.902L133.735 231.566L137.009 228.886L143.57 223.56L150.158 218.262L153.458 215.629L155.112 214.306L156.773 212.997C161.187 209.5 165.622 206.024 170.104 202.602Z"
      fill="#E3E9F7"
    />
    <path
      d="M229.733 175.571C229.733 180.647 225.622 184.757 220.553 184.757C215.477 184.757 211.366 180.647 211.366 175.577C211.366 170.501 215.477 166.391 220.546 166.391C225.622 166.391 229.733 170.501 229.733 175.571Z"
      fill="#F6471E"
    />
    <path
      d="M222.74 179.984H220.877L220.87 174.881L220.891 174.044L220.924 173.126C220.614 173.437 220.398 173.639 220.276 173.734L219.264 174.551L218.366 173.43L221.201 171.162H222.733L222.74 179.984Z"
      fill="white"
    />
    <path
      d="M114.733 204.237C115.59 204.386 116.609 202.84 117.595 201.153L122.401 189.867C122.61 189.293 122.597 188.645 122.36 188.071L119.559 181.314L116.204 184.008L119.33 189.286L116.636 197.737C115.016 198.804 115.165 201.375 114.733 204.237Z"
      fill="#DB965F"
    />
    <path
      d="M102.306 216.158L102.32 216.03L109.293 197.225L109.637 196.313L100.45 192.932L96.7511 209.165L95.8128 214.079C95.7116 214.613 96.0693 215.126 96.6026 215.213L102.306 216.158Z"
      fill="#040C2B"
    />
    <path
      d="M104.567 233.424C104.567 233.424 106.302 238.494 105.951 239.81L110.615 239.702L107.416 231.467L104.567 233.424Z"
      fill="#DB965F"
    />
    <path
      d="M106.822 243.064C106.822 243.064 103.359 244.812 100.389 244.657C99.9437 244.637 99.586 244.252 99.5792 243.793C99.5725 243.226 99.9032 242.483 101.348 241.896C101.348 241.896 104.986 239.189 105.789 237.488C105.789 237.488 108.381 238.204 109.562 236.955C109.562 236.955 110.791 237.522 111.304 239.054C111.675 240.168 111.115 241.37 110.055 241.741C108.948 242.132 107.483 242.679 106.822 243.064Z"
      fill="#040C2B"
    />
    <path
      d="M102.205 213.297L108.3 233.047L107.436 233.385L103.906 234.762L95.7993 214.66L102.205 213.297Z"
      fill="#040C2B"
    />
    <path
      opacity="0.1"
      d="M101.01 214.881L107.436 233.383L108.3 233.045L102.718 214.955L109.293 197.223L106.667 195.104L104.932 200.369L101.01 214.881Z"
      fill="black"
    />
    <path
      d="M108.503 235.165C108.503 235.165 108.523 240.524 107.767 241.658L112.215 243.056L111.831 234.227L108.503 235.165Z"
      fill="#DB965F"
    />
    <path
      d="M107.402 245.6C107.402 245.6 103.562 246.147 100.794 245.047C100.382 244.885 100.166 244.406 100.308 243.967C100.484 243.434 101.03 242.826 102.59 242.745C102.59 242.745 106.903 241.341 108.212 239.984C108.212 239.984 110.595 241.226 112.114 240.423C112.114 240.423 112.937 241.625 112.931 243.238C112.931 244.412 112.013 245.371 110.892 245.384C109.718 245.398 108.152 245.452 107.402 245.6Z"
      fill="#040C2B"
    />
    <path
      d="M106.275 219.289L107.618 237.157L112.532 237.406L113.754 216.677L115.502 206.262C115.671 205.378 116.582 203.778 116.697 203.002C117.122 200.14 116.434 198.763 116.387 197.257C116.326 195.367 116.069 194.49 116.069 194.49L108.104 192.391L106.403 209.563C106.086 212.796 106.039 216.056 106.275 219.289Z"
      fill="#040C2B"
    />
    <path
      d="M100.437 189.9L100.639 189.347L102.846 183.204L101.085 182.934L100.281 182.813L98.6478 182.563L97.858 185.823L97.6623 186.64L89.1978 184.008C89.1978 184.008 88.7658 182.678 87.4158 181.314C87.112 181.389 86.977 181.76 87.1525 182.03L87.679 182.82C87.679 182.82 85.7283 181.517 84.3108 182.05L86.7205 186.64L87.328 186.748L99.1338 190.535C99.6603 190.704 100.221 190.427 100.437 189.9Z"
      fill="#DB965F"
    />
    <path
      d="M112.168 174.315L112.148 178.068L106.66 179.087L103.467 177.433L104.844 175.611L105.607 174.605C105.762 174.403 105.83 174.146 105.789 173.89L105.121 169.583L110.474 167.146L110.022 170.569L109.947 171.129C109.785 172.958 112.168 174.315 112.168 174.315Z"
      fill="#C9885B"
    />
    <path
      opacity="0.1"
      d="M110.474 167.146L109.967 171.028C108.091 174.45 105.722 175.253 104.844 175.611L105.607 174.605C105.762 174.403 105.83 174.146 105.789 173.89L105.121 169.583L110.474 167.146Z"
      fill="black"
    />
    <path
      d="M111.25 167.538L110.615 169.165C109.927 170.211 109.245 171.008 108.611 171.629C106.808 173.377 105.418 173.62 105.418 173.62C104.817 173.714 104.331 173.262 103.94 172.54C102.833 170.501 102.475 166.316 102.475 166.316L109.373 165.473L111.25 167.538Z"
      fill="#DB965F"
    />
    <path
      d="M111.284 168.807C111.203 171.453 109.94 171.277 109.94 171.277L110.048 170.008L110.514 169.225C111.25 167.369 109.947 167.578 109.947 167.578C108.881 167.875 108.928 169.225 108.928 169.225H108.557C108.314 168.847 108.233 167.538 108.233 167.538C105.647 164.453 102.63 167.673 102.63 167.673C100.207 165.891 102.185 164.655 102.185 164.655C101.834 162.077 104.358 162.576 104.358 162.576C104.358 162.576 106.039 161.091 107.031 162.03C107.031 162.03 108.563 161.928 109.11 163.515C109.11 163.515 110.494 163.218 110.939 164.655C110.939 164.655 110.98 164.703 111.041 164.79C111.702 165.742 111.851 166.957 111.466 168.057C111.371 168.334 111.29 168.618 111.284 168.807Z"
      fill="black"
    />
    <path
      d="M108.907 169.206L108.664 171.183C108.631 171.48 108.489 171.75 108.266 171.946C106.633 173.404 105.418 173.62 105.418 173.62C104.817 173.715 104.331 173.262 103.939 172.54C106.41 172.311 108.516 169.01 108.516 169.01L108.907 169.206Z"
      fill="black"
    />
    <path
      d="M101.429 187.08L100.302 186.675L97.9796 185.825L97.4666 185.643C98.7491 178.589 99.9843 177.158 100.551 176.895C100.761 176.793 100.875 176.854 100.875 176.854L101.206 182.936L101.429 187.08Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M102.968 183.413L100.761 189.434L100.302 186.808L100.403 183.028L100.551 177.23C100.761 177.135 100.875 177.189 100.875 177.189L101.206 183.149L102.968 183.413Z"
      fill="black"
    />
    <path
      d="M99.7345 196.232C99.8222 196.65 100.133 196.988 100.544 197.089C101.125 197.231 102.333 197.521 103.319 197.703C104.304 197.886 105.445 198.061 106.673 198.183C109.704 198.5 113.234 198.493 116.029 197.467C116.177 197.413 116.312 197.319 116.42 197.211C116.623 197.008 116.744 196.725 116.751 196.421V196.232L116.758 195.901L116.785 194.551L116.832 192.033L116.866 190.353L116.913 187.828L116.947 186.147L116.974 184.615C116.974 184.588 116.974 184.561 116.974 184.534L116.927 183.63L116.92 183.434L116.846 181.949L116.636 177.744L116.616 177.325L112.58 174.558L112.397 174.423L111.263 173.646C111.263 173.646 112.067 175.212 108.354 177.028C108.057 177.17 107.733 177.244 107.409 177.251C107.333 177.251 107.258 177.246 107.186 177.237C106.862 177.21 106.552 177.109 106.268 176.934C105.654 176.549 105.168 175.847 105.661 174.544L104.392 175.118L104.25 175.179L100.76 176.765C100.578 176.846 100.463 177.035 100.47 177.244L100.484 177.744L100.538 179.424L100.619 181.949L100.673 183.63L100.754 186.147L100.808 187.828L100.821 188.112C100.821 188.161 100.819 188.211 100.814 188.26L100.511 190.353L100.268 192.033L99.9032 194.551L99.7075 195.874C99.6985 195.996 99.7075 196.115 99.7345 196.232Z"
      fill="white"
    />
    <path
      d="M100.484 177.744L100.538 179.425H116.717L116.636 177.744H100.484Z"
      fill="#EEF2FA"
    />
    <path
      d="M100.619 181.951L100.673 183.632H116.927L116.92 183.436L116.846 181.951H100.619Z"
      fill="#EEF2FA"
    />
    <path
      d="M100.754 186.148L100.808 187.829H116.913L116.947 186.148H100.754Z"
      fill="#EEF2FA"
    />
    <path
      d="M100.268 192.034H116.832L116.866 190.354H100.511L100.268 192.034Z"
      fill="#EEF2FA"
    />
    <path
      d="M99.7345 196.233H116.751L116.758 195.903L116.785 194.553H99.9032L99.7075 195.876C99.6985 195.997 99.7075 196.116 99.7345 196.233Z"
      fill="#EEF2FA"
    />
    <path
      opacity="0.1"
      d="M115.017 181.766L116.421 197.21C116.623 197.007 116.745 196.724 116.751 196.42L116.758 195.9L116.974 184.614C116.979 184.592 116.979 184.565 116.974 184.533L116.92 183.433L115.017 181.766Z"
      fill="black"
    />
    <path
      d="M115.779 177.244C115.779 177.244 118.128 176.468 121.544 184.743L117.919 187.565C117.919 187.565 111.911 179.006 115.779 177.244Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M107.801 177.756C107.483 177.682 107.429 177.291 107.423 177.264C107.483 177.493 107.645 177.648 107.801 177.756Z"
      fill="black"
    />
    <path
      d="M89.2449 187.539L87.1726 187.255L83.2711 178.311C83.2509 178.264 83.2711 178.197 83.3251 178.163L83.4061 178.109C83.4534 178.075 83.5141 178.062 83.5681 178.082L83.7639 178.143L89.3394 187.316C89.3529 187.343 89.3529 187.323 89.3664 187.356L89.3731 187.404C89.3934 187.478 89.3191 187.552 89.2449 187.539Z"
      fill="black"
    />
    <path
      d="M83.5344 178.061L85.4784 178.736L89.4002 187.409L87.4022 187.072L83.5344 178.061Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M85.7482 180.774C86.16 181.024 86.781 181.935 87.0172 182.792C87.1455 183.265 87.0982 183.521 86.9227 183.535L87.0915 184.048L86.7067 183.46C86.592 183.393 86.457 183.265 86.3152 183.089C85.89 182.583 85.5255 181.827 85.4175 181.321C85.3095 180.828 85.431 180.578 85.7482 180.774Z"
      fill="black"
    />
    <path
      d="M85.4105 181.301C85.5253 181.808 85.8897 182.564 86.3082 183.063C86.8685 183.738 87.26 183.684 87.0103 182.759C86.774 181.909 86.153 180.998 85.7412 180.748C85.4307 180.559 85.3025 180.815 85.4105 181.301Z"
      fill="#ACBCF8"
    />
    <path
      d="M87.0845 184.015L86.8955 183.448L86.6863 183.414L87.0845 184.015Z"
      fill="#ACBCF8"
    />
    <path
      d="M86.3021 182.021L86.0794 181.61C85.9376 181.34 85.7621 181.178 85.6879 181.225C85.6204 181.272 85.6541 181.488 85.7689 181.738L85.9444 182.102C85.9714 182.156 85.9781 182.204 85.9579 182.204C85.9444 182.204 85.9511 182.251 85.9781 182.305L86.0051 182.359C86.0254 182.413 86.0591 182.453 86.0794 182.453L86.5519 182.413C86.5721 182.413 86.5654 182.359 86.5316 182.291L86.4979 182.224C86.4641 182.163 86.4169 182.116 86.3966 182.123C86.3696 182.123 86.3291 182.082 86.3021 182.021Z"
      fill="white"
    />
    <path
      d="M85.7484 181.339C85.7349 181.346 85.7079 181.319 85.6877 181.285L85.6472 181.198C85.6269 181.157 85.6269 181.123 85.6337 181.117C85.6472 181.11 85.6674 181.13 85.6877 181.171L85.7349 181.258C85.7484 181.292 85.7552 181.333 85.7484 181.339Z"
      fill="white"
    />
    <path
      d="M86.4234 182.533C86.4572 182.594 86.4774 182.655 86.4842 182.695C86.4909 182.736 86.4842 182.763 86.4572 182.763C86.4167 182.763 86.3357 182.661 86.2749 182.54L86.4234 182.533Z"
      fill="white"
    />
    <path
      d="M85.9917 181.344C86.0524 181.459 86.0727 181.567 86.0322 181.581C85.9917 181.594 85.9174 181.52 85.8634 181.412C85.8094 181.311 85.7959 181.209 85.8297 181.189C85.8634 181.169 85.9377 181.236 85.9917 181.344Z"
      fill="#ACBCF8"
    />
    <path
      d="M85.978 181.354C86.032 181.442 86.0455 181.53 86.0118 181.543C85.9848 181.557 85.9173 181.496 85.8768 181.408C85.8295 181.327 85.8161 181.246 85.8498 181.233C85.8768 181.213 85.9375 181.267 85.978 181.354Z"
      fill="#F6471E"
    />
    <path
      d="M85.9781 181.456H85.9714L85.9241 181.368L85.9174 181.355L85.9106 181.341C85.9106 181.346 85.9106 181.35 85.9106 181.355V181.368L85.8971 181.355L85.8904 181.308L85.8971 181.301L85.9781 181.456Z"
      fill="white"
    />
    <path
      d="M86.7203 186.642L87.3346 186.028C88.1783 184.313 87.1996 182.882 86.4908 182.146C86.1533 181.802 85.7011 181.6 85.2218 181.586L84.9316 181.573C84.4793 181.559 84.1013 181.924 84.1013 182.376C84.1081 183.726 84.3106 184.961 86.7203 186.642Z"
      fill="#DB965F"
    />
    <path
      d="M139 86.0566L143.833 96.7486C143.934 96.9646 143.954 97.2009 143.914 97.4169C143.866 97.6126 143.779 97.7949 143.644 97.9434C143.542 98.0446 143.428 98.1324 143.293 98.1931L127.957 105.125C127.815 105.193 127.673 105.22 127.531 105.227C127.336 105.233 127.133 105.179 126.964 105.085C126.769 104.97 126.613 104.801 126.512 104.585L121.672 93.8866C121.598 93.7246 121.571 93.5491 121.578 93.3804C121.591 93.1846 121.652 92.9956 121.76 92.8404C121.868 92.6716 122.023 92.5366 122.219 92.4489L137.555 85.5099C137.751 85.4221 137.953 85.3951 138.156 85.4221C138.345 85.4491 138.527 85.5234 138.676 85.6449C138.811 85.7529 138.925 85.8879 139 86.0566Z"
      fill="#829CF8"
    />
    <path
      d="M138.675 85.6427C138.527 85.5212 138.345 85.4469 138.156 85.4199L133.64 95.2614L133.404 95.7879L133.356 95.8892C133.255 96.1052 133.012 96.2132 132.789 96.1457L132.681 96.1119L132.128 95.9499L121.76 92.8382C121.652 92.9934 121.591 93.1824 121.578 93.3782L131.838 96.4562L126.964 105.083C127.133 105.177 127.335 105.231 127.531 105.224L132.398 96.6182L132.627 96.6857C132.87 96.7599 133.127 96.7397 133.35 96.6384C133.572 96.5372 133.761 96.3617 133.869 96.1254L133.971 95.9094L143.643 97.9412C143.778 97.7927 143.866 97.6104 143.913 97.4147L134.214 95.3829L138.675 85.6427Z"
      fill="#E3E9F7"
    />
    <path
      d="M181.876 49.0033L179.028 60.3838C178.974 60.62 178.845 60.8158 178.677 60.9575C178.521 61.0858 178.332 61.1668 178.137 61.2005C177.995 61.2208 177.853 61.214 177.705 61.1803L161.376 57.0965C161.235 57.0628 161.1 56.9953 160.985 56.9075C160.83 56.7928 160.701 56.6308 160.627 56.4418C160.546 56.2393 160.526 56.003 160.587 55.7735L163.435 44.3863C163.476 44.2108 163.563 44.0555 163.678 43.9273C163.806 43.7855 163.968 43.6775 164.151 43.6168C164.34 43.5493 164.549 43.5425 164.758 43.5965L181.08 47.6803C181.289 47.7275 181.471 47.8355 181.606 47.984C181.741 48.119 181.836 48.2945 181.876 48.4768C181.917 48.6455 181.923 48.821 181.876 49.0033Z"
      fill="#829CF8"
    />
    <path
      d="M181.876 48.4791C181.835 48.2969 181.741 48.1214 181.606 47.9864L171.96 52.8936L171.447 53.1636L171.346 53.2109C171.137 53.3189 170.873 53.2514 170.738 53.0624L170.678 52.9679L170.347 52.4954L164.15 43.6191C163.968 43.6799 163.806 43.7879 163.678 43.9296L169.807 52.7114L160.627 56.4441C160.701 56.6331 160.829 56.7951 160.985 56.9099L170.138 53.1906L170.279 53.3796C170.428 53.5956 170.644 53.7374 170.88 53.7981C171.116 53.8521 171.373 53.8319 171.602 53.7171L171.818 53.6091L178.136 61.2029C178.332 61.1691 178.521 61.0881 178.676 60.9599L172.331 53.3459L181.876 48.4791Z"
      fill="#E3E9F7"
    />
    <path
      d="M81.2409 202.284L77.6229 213.456C77.5487 213.678 77.4069 213.867 77.2247 213.996C77.0627 214.11 76.8737 214.185 76.6779 214.205C76.5362 214.218 76.3877 214.198 76.2459 214.151L60.2349 208.967C60.0932 208.92 59.9649 208.845 59.8569 208.751C59.7084 208.623 59.5937 208.454 59.5329 208.265C59.4654 208.056 59.4587 207.819 59.5329 207.59L63.1509 196.425C63.2049 196.25 63.3062 196.101 63.4277 195.98C63.5627 195.845 63.7382 195.75 63.9204 195.703C64.1162 195.656 64.3254 195.656 64.5279 195.723L80.5389 200.914C80.7414 200.975 80.9102 201.096 81.0384 201.252C81.1599 201.4 81.2409 201.576 81.2747 201.765C81.3017 201.933 81.2949 202.116 81.2409 202.284Z"
      fill="#829CF8"
    />
    <path
      d="M81.2742 201.765C81.2405 201.576 81.1595 201.4 81.038 201.252L71.0817 205.497L70.5485 205.72L70.4472 205.767C70.2312 205.855 69.9747 205.774 69.8532 205.572L69.7925 205.477L69.4955 204.984L63.92 195.703C63.7377 195.75 63.5622 195.845 63.4272 195.98L68.942 205.167L59.5325 208.265C59.5932 208.454 59.708 208.623 59.8565 208.751L69.2457 205.659L69.3672 205.869C69.5022 206.085 69.7047 206.24 69.941 206.321C70.1705 206.395 70.427 206.388 70.67 206.287L70.886 206.193L76.6775 214.205C76.8732 214.185 77.0622 214.11 77.2242 213.996L71.4192 205.963L81.2742 201.765Z"
      fill="#E3E9F7"
    />
    <path
      d="M232.683 97.2318L234.013 93.1413C234.971 90.1848 233.351 87.0123 230.395 86.0538C227.438 85.0953 224.266 86.7086 223.307 89.6651L221.978 93.7556C221.809 94.2956 221.228 94.5926 220.688 94.4171C220.148 94.2483 219.575 94.5386 219.399 95.0718L219.217 95.6388C219.048 96.1721 219.338 96.7458 219.878 96.9213L232.535 101.025C233.068 101.201 233.642 100.904 233.817 100.371L233.999 99.8103C234.175 99.2703 233.878 98.6966 233.345 98.5211C232.805 98.3523 232.508 97.7718 232.683 97.2318Z"
      fill="#829CF8"
    />
    <path
      d="M230.213 87.5532L229.659 87.3777C229.342 87.2765 229.173 86.9322 229.274 86.6217L229.814 84.9545C229.916 84.644 230.253 84.4685 230.57 84.5697L231.124 84.752C231.441 84.8532 231.61 85.1907 231.509 85.508L230.969 87.1685C230.867 87.4857 230.53 87.6612 230.213 87.5532Z"
      fill="#829CF8"
    />
    <path
      d="M227.864 100.499C227.688 101.032 227.31 101.444 226.851 101.68C226.379 101.916 225.825 101.977 225.285 101.801C224.212 101.45 223.625 100.303 223.969 99.2363L227.864 100.499Z"
      fill="#829CF8"
    />
    <path
      d="M237.651 89.7665C237.563 89.7328 237.482 89.6585 237.455 89.5573C237.287 88.9835 237.064 88.4165 236.794 87.8833C236.517 87.3433 236.193 86.837 235.822 86.3645C235.721 86.2295 235.748 86.0405 235.876 85.9393C236.011 85.838 236.2 85.8583 236.301 85.9865C236.693 86.4928 237.044 87.0395 237.334 87.6065C237.624 88.1803 237.86 88.781 238.036 89.3953C238.083 89.5505 237.989 89.7193 237.833 89.7665C237.773 89.78 237.712 89.78 237.651 89.7665Z"
      fill="#E3E9F7"
    />
    <path
      d="M222.342 84.7989C222.281 84.7786 222.234 84.7449 222.194 84.6976C222.092 84.5626 222.113 84.3736 222.248 84.2724C222.754 83.8809 223.294 83.5299 223.868 83.2396C224.435 82.9494 225.035 82.7131 225.65 82.5376C225.812 82.4904 225.98 82.5849 226.021 82.7401C226.068 82.9021 225.98 83.0709 225.818 83.1181C225.238 83.2801 224.678 83.5029 224.138 83.7796C223.604 84.0496 223.091 84.3804 222.619 84.7449C222.538 84.8124 222.43 84.8259 222.342 84.7989Z"
      fill="#E3E9F7"
    />
    <path
      d="M223.456 86.2224C223.402 86.2089 223.348 86.1684 223.308 86.1211C223.206 85.9929 223.233 85.8039 223.362 85.6959C223.773 85.3786 224.219 85.0884 224.691 84.8521C225.157 84.6159 225.65 84.4201 226.149 84.2784C226.311 84.2311 226.48 84.3189 226.527 84.4809C226.575 84.6429 226.48 84.8116 226.318 84.8589C225.852 84.9871 225.393 85.1694 224.961 85.3921C224.529 85.6149 224.118 85.8781 223.733 86.1751C223.652 86.2359 223.551 86.2561 223.456 86.2224Z"
      fill="#E3E9F7"
    />
    <path
      d="M235.91 90.267C235.822 90.2333 235.741 90.159 235.714 90.0645C235.579 89.5988 235.404 89.1398 235.181 88.7078C234.958 88.2758 234.695 87.8573 234.398 87.4793C234.297 87.3443 234.317 87.1553 234.452 87.054C234.58 86.9528 234.769 86.973 234.87 87.108C235.194 87.5198 235.478 87.9653 235.721 88.431C235.957 88.8968 236.153 89.3895 236.295 89.8958C236.342 90.0578 236.247 90.2198 236.092 90.267C236.031 90.2873 235.971 90.2805 235.91 90.267Z"
      fill="#E3E9F7"
    />
    <path
      d="M84.0417 149.756L81.7602 146.104C80.1132 143.472 76.6437 142.675 74.0044 144.322C71.3719 145.969 70.5754 149.439 72.2224 152.071L74.5039 155.716C74.8009 156.202 74.6524 156.837 74.1732 157.134C73.6939 157.431 73.5522 158.065 73.8492 158.538L74.1664 159.044C74.4634 159.517 75.0912 159.665 75.5704 159.368L86.8497 152.308C87.3289 152.011 87.4707 151.383 87.1737 150.904L86.8632 150.404C86.5594 149.925 85.9317 149.783 85.4592 150.08C84.9732 150.384 84.3387 150.235 84.0417 149.756Z"
      fill="#829CF8"
    />
    <path
      d="M75.044 145.429L74.5512 145.733C74.2677 145.908 73.8965 145.827 73.721 145.544L72.7962 144.059C72.6207 143.782 72.7017 143.411 72.9852 143.235L73.478 142.925C73.7615 142.749 74.1327 142.837 74.3082 143.114L75.233 144.599C75.4085 144.875 75.3207 145.247 75.044 145.429Z"
      fill="#829CF8"
    />
    <path
      d="M83.4409 155.547C83.7379 156.026 83.8122 156.573 83.6974 157.079C83.5759 157.592 83.2654 158.058 82.7862 158.355C81.8277 158.956 80.5722 158.665 79.9714 157.714L83.4409 155.547Z"
      fill="#829CF8"
    />
    <path
      d="M81.5168 141.146C81.4358 141.2 81.3278 141.213 81.2333 141.166C80.6865 140.923 80.106 140.734 79.5255 140.599C78.9383 140.464 78.3375 140.383 77.7368 140.363C77.568 140.356 77.4398 140.214 77.4465 140.052C77.4465 139.883 77.5883 139.755 77.757 139.762C78.3983 139.782 79.0328 139.863 79.6605 140.012C80.2815 140.153 80.8958 140.356 81.4763 140.619C81.6315 140.687 81.699 140.862 81.6315 141.017C81.6045 141.071 81.564 141.119 81.5168 141.146Z"
      fill="#E3E9F7"
    />
    <path
      d="M67.8683 149.684C67.8143 149.718 67.7603 149.732 67.6928 149.732C67.5308 149.725 67.3958 149.583 67.4026 149.421C67.4296 148.78 67.5106 148.139 67.6523 147.518C67.8008 146.89 68.0033 146.282 68.2598 145.695C68.3273 145.54 68.5096 145.472 68.6581 145.54C68.8133 145.607 68.8808 145.79 68.8133 145.938C68.5703 146.492 68.3813 147.065 68.2463 147.653C68.1113 148.24 68.0303 148.841 68.0101 149.441C68.0033 149.543 67.9493 149.63 67.8683 149.684Z"
      fill="#E3E9F7"
    />
    <path
      d="M69.6773 149.752C69.63 149.779 69.5693 149.8 69.5085 149.793C69.3398 149.786 69.2115 149.651 69.2183 149.482C69.2318 148.956 69.306 148.436 69.4208 147.923C69.5355 147.41 69.7043 146.911 69.9203 146.431C69.9878 146.276 70.1633 146.209 70.3185 146.276C70.467 146.344 70.5413 146.526 70.4738 146.674C70.2713 147.12 70.116 147.586 70.008 148.058C69.9 148.531 69.8393 149.017 69.819 149.503C69.8123 149.611 69.7583 149.698 69.6773 149.752Z"
      fill="#E3E9F7"
    />
    <path
      d="M80.7809 142.806C80.6999 142.853 80.5919 142.867 80.4974 142.826C80.0519 142.63 79.5862 142.475 79.1137 142.36C78.6412 142.252 78.1551 142.192 77.6691 142.171C77.5071 142.165 77.3722 142.03 77.3789 141.861C77.3857 141.692 77.5274 141.564 77.6894 141.571C78.2159 141.591 78.7424 141.658 79.2487 141.773C79.7617 141.895 80.2611 142.063 80.7404 142.273C80.8956 142.34 80.9632 142.516 80.8957 142.671C80.8687 142.725 80.8281 142.772 80.7809 142.806Z"
      fill="#E3E9F7"
    />
  </svg>
);
export default NotCampaign;
