import moment from "moment";
import { formatTime } from "../../utils/formatDate";
import { convertUTCDateToLocalDate } from "../../utils/parseTimeZone";

const Items = ({ subscriberId, item, onClickItem }) => (
  <button
    className={`flex w-full justify-start items-start self-start px-5 py-5 border-solid border-b border-gray-light cursor-pointer hover-effect ${
      subscriberId === item?.subscriber_id ? "active" : ""
    }`}
    onClick={onClickItem}
  >
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/89135f6ffecf3b6bd615b8afc85b4b77754bda177063f5f26f4d4ca8226589da?"
      className="shrink-0 my-auto w-10 aspect-square"
      alt=""
    />
    <div className="flex flex-col w-full ml-3">
      <div className="flex flex-between">
        <div className="flex-1 text-[14px] font-medium leading-[20px] text-blue text-left">
          {item?.phone_no}
        </div>
        <div className="text-zinc-600 leading-[22px] text-[14px] font-normal">
          {moment(
            formatTime(convertUTCDateToLocalDate(item?.messaged_at))
          ).format("DD/MM/YYYY")}
        </div>
      </div>
      <div className="leading-[22px] text-[14px] font-normal text-left w-[328px] text-zinc-600 whitespace-nowrap overflow-hidden overflow-ellipsis">
        {item?.last_message}
      </div>
    </div>
  </button>
);

export default Items;
