import RenderTable from "../../../../components/RenderTable";
import columns from "./DatatableConfig";

const SendingNumber = ({ data }) => (
  <div className="flex flex-col">
    <div className="mx-5 text-[16px] text-blue leading-[20px] font-bold max-md:max-w-full">
      Sending Number
    </div>
    <div className="my-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
    <RenderTable isFilterLayout={false} columns={columns} tableData={data} />
  </div>
);

export default SendingNumber;
