const SMSAbandonmentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    className="w-6"
  >
    <g>
      <path
        d="m353.037 321.121-8.505 59.158M166.371 378.538l-7.408-59.306M452.051 238.41H59.949C37.886 238.41 20 220.524 20 198.461v0c0-22.063 17.886-39.949 39.949-39.949h392.102c22.063 0 39.949 17.886 39.949 39.949v0c0 22.063-17.886 39.949-39.949 39.949zM111.433 157.704l113.712-97.729c16.397-14.093 40.586-14.254 57.171-.381l118.252 98.917M256 319.232v59.306"
        fill="none"
        stroke="#0375ff"
        strokeWidth="40"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="m441.058 310.799 10.465-72.389H60.477L81.82 410.247c3.721 29.961 29.188 52.456 59.387 52.456h226.415c29.722 0 54.929-21.807 59.216-51.19"
        fill="none"
        stroke="#0375ff"
        strokeWidth="40"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);
export default SMSAbandonmentIcon;
