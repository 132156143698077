import { Tooltip } from "react-tooltip";
import tooltipData from "../../utils/tooltipData";

const CustomTooltip = () => {
  return (
    <>
      {tooltipData.map((item) => (
        <Tooltip
          key={item?.id}
          id={item?.id}
          place="right"
          content={item?.title}
          className="tooltip z-20"
        />
      ))}
    </>
  );
};
export default CustomTooltip;
