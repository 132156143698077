const UpIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.91549 13.096L4.78652 7.14862H15.0445L9.91549 13.096Z"
      fill={color ? color : "#EAEAEA"}
      stroke={color ? color : "#EAEAEA"}
      strokeWidth="1.54139"
    />
  </svg>
);
export default UpIcon;
