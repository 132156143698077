import { useState } from "react";
import MessageDetails from "../../components/MessageDetails";
import MessageItemView from "../../components/MessageItemView";
import MessageView from "../../components/MessageView";

const InboxPage = () => {
  const [subscriberId, setSubscriberId] = useState();
  return (
    <div className="relative">
      <div className="flex justify-between gap-5 px-6 pt-8 mb-8 bg-white rounded-[10px] border border-gray-dark border-solid relative h-[100vh]">
        <MessageItemView
          subscriberId={subscriberId}
          onClickItem={(item) => setSubscriberId(item?.subscriber_id)}
        />
        <MessageView subscriberId={subscriberId} />
        <MessageDetails subscriberId={subscriberId} />
      </div>
    </div>
  );
};
export default InboxPage;
