import NormalText from "../../components/RenderTableComponents/NormalText";
import Status from "../../components/RenderTableComponents/Status";

const columns = [
  {
    name: "Name",
    selector: (row) => row.UserType,
    sortable: true,
    width: "40%",
    cell: (row) => <NormalText row={row?.UserType} />,
  },
  {
    name: "Role Type",
    selector: (row) => row.members,
    sortable: true,
    width: "25%",
    cell: (row) => <NormalText row={row?.user_type_term} />,
  },
  {
    name: "Role Name",
    selector: (row) => row.role_detail,
    sortable: true,
    width: "25%",
    cell: (row) => <NormalText row={row.role_detail} />,
  },
  {
    name: "Status",
    selector: (row) => row.is_active,
    sortable: true,
    cell: (row) => (
      <Status row={row.is_active === "1" ? "Active" : "unActive"} />
    ),
  },
];
export default columns;
