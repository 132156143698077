const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
  >
    <path
      d="M6.94257 16.9922H5.64955C5.17705 16.9922 4.79346 17.3758 4.79346 17.8483V19.1413C4.79346 19.6138 5.17705 19.9999 5.64955 19.9999H6.94257C7.41507 19.9999 7.8012 19.6138 7.8012 19.1413V17.8483C7.8012 17.3758 7.41507 16.9922 6.94257 16.9922Z"
      fill="#2B61AB"
    />
    <path
      d="M10.9488 1.58516C9.798 0.533469 8.24078 0 6.31522 0C4.49888 0 3.01533 0.495365 1.90267 1.47593C0.952588 2.31424 0.317506 3.48279 0.0177474 4.94602C-0.0305188 5.18227 0.020288 5.42868 0.162546 5.62428C0.302264 5.81735 0.518191 5.94436 0.756982 5.97231L1.81376 6.09932C2.24815 6.15013 2.64953 5.8707 2.7486 5.44392C2.98231 4.44303 3.37606 3.67585 3.92223 3.16525C4.56747 2.56065 5.388 2.25327 6.35586 2.25327C7.35929 2.25327 8.21792 2.59367 8.90889 3.26178C9.59732 3.92989 9.94534 4.71485 9.94534 5.59126C9.94534 6.07392 9.82849 6.5261 9.59478 6.93255C9.35853 7.34662 8.8276 7.9182 8.01977 8.63203C7.19671 9.35856 6.61498 9.92252 6.29489 10.3061C5.85288 10.837 5.53026 11.3705 5.33211 11.8938C5.0603 12.5873 4.92566 13.418 4.92566 14.3655V14.3732C4.92566 14.8457 5.30925 15.2293 5.78175 15.2293H6.68865C7.1459 15.2293 7.52187 14.8736 7.54473 14.4164C7.57522 13.8194 7.63364 13.3621 7.71493 13.0573C7.82417 12.6534 7.99437 12.2926 8.22046 11.9827C8.45163 11.6677 8.95461 11.1647 9.71925 10.4865C10.8802 9.46018 11.6728 8.57106 12.0767 7.84453C12.4831 7.11292 12.6889 6.30001 12.6889 5.43376C12.6889 3.93497 12.1046 2.6394 10.9488 1.58516Z"
      fill="#2B61AB"
    />
  </svg>
);

export default QuestionIcon;
