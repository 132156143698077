import Loader from "react-js-loader";
const CustomLoader = () => {
  return (
    <div
      className="absolute left-0 bottom-0 right-0 top-0 flex justify-center items-center z-10"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
    >
      <Loader type="bubble-top" bgColor={"#2B61AB"} size={50} />
    </div>
  );
};
export default CustomLoader;
