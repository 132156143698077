const FulfillNotificationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 682.667 682.667"
    className="w-6"
  >
    <g>
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 512h512V0H0Z"
            fill="#0375ff"
            opacity="1"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          d="M0 0c0-78.706-63.804-142.51-142.51-142.51-78.706 0-142.51 63.804-142.51 142.51 0 78.706 63.804 142.51 142.51 142.51C-63.804 142.51 0 78.706 0 0Z"
          transform="translate(502 174.761)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
        <path
          d="M0 0c-54.318 0-98.51 44.191-98.51 98.51 0 54.318 44.192 98.51 98.51 98.51 54.318 0 98.51-44.192 98.51-98.51C98.51 44.191 54.318 0 0 0Z"
          transform="translate(359.49 76.251)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
        <path
          d="M0 0v-54.169h26.219"
          transform="translate(359.49 228.93)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
        <path
          d="m0 0-5.984 28.193c-4.274 18.669 9.953 36.458 29.155 36.458 19.203 0 33.429-17.789 29.156-36.458L46.342 0"
          transform="translate(139.658 415.098)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
        <path
          d="M0 0h-218.404v51.466h205.98"
          transform="translate(228.404 113.295)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
        <path
          d="M0 0h-.158C-22.548 0-40.7 18.151-40.7 40.542v13.084h81.242V40.542C40.542 18.151 22.391 0 0 0Z"
          transform="translate(162.908 59.67)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
        <path
          d="M0 0c-6.048 64.511-60.496 115.001-126.77 115.001h-1.329c-70.321 0-127.328-56.846-127.328-126.97v-122.596"
          transform="translate(290.264 300.326)"
          fill="none"
          stroke="#0375ff"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="none"
          strokeOpacity=""
          data-original="#000000"
        ></path>
      </g>
    </g>
  </svg>
);
export default FulfillNotificationIcon;
