export function isValid(input, type) {
   const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
   // it will validate for following strings:
   // (123) 456-7890
   // (123)456-7890
   // 123-456-7890
   // 1234567890

   const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
   // it will validate : 11101 or 21043-4012

   switch (type) {
      case 'text':
         return typeof input === 'string' && input.trim().length > 0;
      case 'email':
         return typeof input === 'string' && input.trim().length > 0 && input.includes('@');
      case 'date':
         return typeof input === 'string' && !!Date.parse(input);
      case 'phone':
         return typeof input === 'string' && phoneRegex.test(input);
      case 'zip':
         return typeof input === 'string' && zipRegex.test(input);
      case 'password':
         return typeof input === 'string' && input.trim().length >= 6;
      default:
         return true;
   }
}

export function isInvalid(input, type) {
   return !isValid(input, type);
}
