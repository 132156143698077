/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useQuery } from "react-query";
import { subscribersInfo } from "../../apis/Inbox";
import ProfileIcon from "../../assets/images/profile.png";
import { formatDate } from "../../utils/formatDate";
import queryParamConstant from "../../utils/queryParamConstant";
import SubscriberSegments from "./SubscriberSegments";

const MessageDetails = ({ subscriberId }) => {
  const { data, refetch } = useQuery(
    queryParamConstant.GET_SUBSCRIBER_INFO_URL,
    () =>
      subscribersInfo({
        subscriber_id: subscriberId,
      }),
    { refetchOnWindowFocus: false, enabled: !!subscriberId }
  );
  useEffect(() => {
    if (subscriberId) {
      setTimeout(() => refetch(), 10);
    }
  }, [subscriberId]);
  return (
    <div className=" message-view flex flex-col w-[30%] pt-5 mx-auto text-sm font-medium bg-white rounded-xl border border-gray-light border-solid text-slate-800 max-md:mt-10 overflow-auto">
      <div className="flex gap-5 justify-start px-5 whitespace-nowrap leading-[22px]">
        <img
          loading="lazy"
          src={ProfileIcon}
          alt="ProfileIcon"
          className="shrink-0 aspect-square w-[60px]"
        />
        <div className="my-auto">{data?.phone_no}</div>
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="mx-5 mt-5 text-blue font-bold max-md:mx-2.5 text-[16px] ">
        Personal info
      </div>
      <div className="mx-5 mt-3 text-blue text-[14px] font-medium leading-[20px] max-md:mx-2.5">
        Location
      </div>
      <div className="mx-5 mt-1text-[14px] leading-[20px] text-zinc-600 max-md:mx-2.5">
        {data?.country_code}
      </div>
      <div className="mx-5 mt-5 text-[14px] text-blue font-medium leading-[20px] max-md:mx-2.5">
        Subscriber’s Current Time
      </div>
      <div className="mx-5 mt-1 text-[14px]  leading-[20px] text-zinc-600 max-md:mx-2.5">
        {formatDate(data?.created_at)}
      </div>
      <div className="mx-5 mt-1 text-red-500 leading-[20px] max-md:mx-2.5">
        Currently in quiet hours.
      </div>
      <div className="mx-5 text-[14px]  text-blue mt-5 font-medium leading-[20px] max-md:mx-2.5">
        Subscriber ID
      </div>
      <div className="mx-5 mt-1 text-[14px] leading-[20px] text-zinc-600 max-md:mx-2.5">
        {data?.subscriber_id}
      </div>
      <SubscriberSegments subscriberId={subscriberId} />
    </div>
  );
};
export default MessageDetails;
